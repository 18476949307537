'use strict';

var controller = function ($scope, app, $http, $location, logger, cart, localStorageService, textBundle, userSessionStore, $state) {
	logger('loader controller');

	this.isLoading = isLoading;

	this.showConfiguration = showConfiguration;

	function isLoading(){
		return app.loading;
	}

	function showConfiguration(){
		return app.showConfiguration;
	}

};

module.exports = function (module) {
	var name = 'LoaderController';
	module.controller(name, controller);

	return name;
};