'use strict';
var angular = require('angular');
var localListManagerService = angular.module('localListManagerService', []);


/**
 * Service for managing lists of arbitraty strings.
 */

var factory = function (app, localStorage) {
	return {
		lists : localStorage.get('localLists') || {},

		save : function() {
			localStorage.add('localLists', this.lists)
		},

		merge : function(list, values){
			if(list && values){
				if(!this.lists[list]){
					this.lists[list] = {};
				}

				for(var i in values){
					if(values[i] != ''){
						this.lists[list][values[i]] = null;
					}
				}
			}
			this.save();
		},

		remove : function(list, value){
			if(list && value){
				if(this.lists[list]){
					delete this.lists[list][value];
				}
			}
			this.save();
		}

	};
};

localListManagerService.factory('localListManager', ['app', 'localStorageService', factory]);
module.exports = localListManagerService.name;
