'use strict';

var controller = function ($scope, app, catalog, $window, categoryPath, categoryService, $http, $location, logger, textBundle, userSessionStore, $state, $stateParams) {
	logger('productListing controller');
	$scope.app = app;
	app.currentPage = $state.current.name;
	app.loading = false;
	$scope.catalog = catalog;
	$scope.stateParams = $stateParams;
	$scope.state = $state;
	$scope.pageNum = null;
	categoryService.currentCategory = $stateParams.catId;
	categoryService.categoryPath = categoryPath;
	$window.scrollTo(0,0);
	$scope.pagestatus = 'ready';
};

module.exports = function (module) {
	var name = 'ProductListingController';
	module.controller(name, controller);

	return name;
};