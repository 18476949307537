'use strict';
var angular = require('angular');
var appStorageService = angular.module('masterpassDataServiceProvider', []);


/**
 * Service for managing data communications with the MasterPass API and back-end service API
 */
var factory = function ($http, traceManager, app, configurationManager) {

	return {
		setMasterpassDataV7 : function(masterpassData, ignoreNull){
			// write back response items
			this._setProperty("accessToken", masterpassData.accessToken, ignoreNull);
			this._setProperty("accessTokenResponse", masterpassData.accessTokenResponse, ignoreNull);
			this._setProperty("callbackUrl", masterpassData.callbackUrl, ignoreNull);
			this._setProperty("checkoutResourceURL", masterpassData.checkoutResourceURL, ignoreNull);
			this._setProperty("checkout", masterpassData.checkout, ignoreNull);
			this._setProperty("checkoutXML", masterpassData.checkoutXML, ignoreNull);
			this._setProperty("cardId", masterpassData.cardId, ignoreNull);
			this._setProperty("consumerWalletId", masterpassData.walletId, ignoreNull);
			this._setProperty("contextPath", masterpassData.contextPath, ignoreNull);
			this._setProperty("currencyCode", masterpassData.currencyCode, ignoreNull);
			this._setProperty("expressCheckoutRequest", masterpassData.expressCheckoutRequest, ignoreNull);
			this._setProperty("expressCheckoutRequestData", masterpassData.expressCheckoutRequestData, ignoreNull);
			this._setProperty("expressCheckoutResponseData", masterpassData.expressCheckoutResponseData, ignoreNull);
			this._setProperty("expressCheckoutResponse", masterpassData.expressCheckoutResponse, ignoreNull);
			this._setProperty("expressCheckoutIndicator", masterpassData.expressCheckoutIndicator, ignoreNull);
			this._setProperty("expressSecurityRequired", masterpassData.expressSecurityRequired, ignoreNull);
			this._setProperty("postTransactionSentXml", masterpassData.postTransactionSentXml, ignoreNull);
			this._setProperty("preCheckoutData", masterpassData.preCheckoutData, ignoreNull);
			this._setProperty("preCheckoutPreCheckoutAuthHeader", masterpassData.preCheckoutPreCheckoutAuthHeader, ignoreNull);
			this._setProperty("preCheckoutRequestTokenAuthHeader", masterpassData.preCheckoutRequestTokenAuthHeader, ignoreNull);
			this._setProperty("preCheckoutPostShoppingCartAuthHeader", masterpassData.preCheckoutPostShoppingCartAuthHeader, ignoreNull);
			this._setProperty("precheckoutCardId", masterpassData.precheckoutCardId, ignoreNull);
			this._setProperty("precheckoutLoyaltyId", masterpassData.precheckoutLoyaltyId, ignoreNull);
			this._setProperty("precheckoutRequest", masterpassData.precheckoutRequest, ignoreNull);
			this._setProperty("precheckoutResponse", masterpassData.precheckoutResponse, ignoreNull);
			this._setProperty("precheckoutShippingId", masterpassData.precheckoutShippingId, ignoreNull);
			this._setProperty("precheckoutTransactionId", masterpassData.precheckoutTransactionId, ignoreNull);
			this._setProperty("requestToken", masterpassData.requestToken, ignoreNull);
			this._setProperty("requestTokenResponse", masterpassData.requestTokenResponse, ignoreNull);
			this._setProperty("walletPartnerLogoUrl", masterpassData.walletPartnerLogoUrl, ignoreNull);
			this._setProperty("masterpassLogoUrl", masterpassData.masterpassLogoUrl, ignoreNull);
			this._setProperty("rewards", masterpassData.rewards, ignoreNull);
			this._setProperty("shipping", masterpassData.shipping, ignoreNull);
			this._setProperty("shippingId", masterpassData.shippingId, ignoreNull);
			this._setProperty("shoppingCart", masterpassData.shoppingCart, ignoreNull);
			this._setProperty("silentlyPaired", masterpassData.silentlyPaired, ignoreNull);
			this._setProperty("securityOption", masterpassData.securityOption, ignoreNull);
			this._setProperty("transactionId", masterpassData.transactionId, ignoreNull);
			this._setProperty("personalInfo", masterpassData.personalInfo, ignoreNull);
			this._setProperty("tokenizedCard", masterpassData.tokenizedCard, ignoreNull);
			this._setProperty("walletId", masterpassData.walletId, ignoreNull);
			this._setProperty("authenticationOptions", masterpassData.authenticationOptions, ignoreNull);

			if(masterpassData.callTraces && masterpassData.callTraces.length){
				traceManager.save(masterpassData.callTraces);
			}

			configurationManager.save();
		},

		setMasterpassData : function(masterpassData, ignoreNull){
			// write back response items
			this._setProperty("accessToken", masterpassData.accessToken, ignoreNull);
			this._setProperty("accessTokenResponse", masterpassData.accessTokenResponse, ignoreNull);
			this._setProperty("callbackUrl", masterpassData.callbackUrl, ignoreNull);
			this._setProperty("checkoutResourceURL", masterpassData.checkoutResourceURL, ignoreNull);
			this._setProperty("checkout", masterpassData.checkout, ignoreNull);
			this._setProperty("checkoutXML", masterpassData.checkoutXML, ignoreNull);
			this._setProperty("cardId", masterpassData.cardId, ignoreNull);
			this._setProperty("consumerWalletId", masterpassData.consumerWalletId, ignoreNull);
			this._setProperty("contextPath", masterpassData.contextPath, ignoreNull);
			this._setProperty("currencyCode", masterpassData.currencyCode, ignoreNull);
			this._setProperty("expressCheckoutRequest", masterpassData.expressCheckoutRequest, ignoreNull);
			this._setProperty("expressCheckoutRequestData", masterpassData.expressCheckoutRequestData, ignoreNull);
			this._setProperty("expressCheckoutResponseData", masterpassData.expressCheckoutResponseData, ignoreNull);
			this._setProperty("expressCheckoutResponse", masterpassData.expressCheckoutResponse, ignoreNull);
			this._setProperty("expressCheckoutIndicator", masterpassData.expressCheckoutIndicator, ignoreNull);
			this._setProperty("expressSecurityRequired", masterpassData.expressSecurityRequired, ignoreNull);
			this._setProperty("longAccessToken", masterpassData.longAccessToken, ignoreNull);
			this._setProperty("merchantInit", masterpassData.merchantInit, ignoreNull);
			this._setProperty("merchantInitRequest", masterpassData.merchantInitRequest, ignoreNull);
			this._setProperty("pairingToken", masterpassData.pairingToken, ignoreNull);
			this._setProperty("pairingVerifier", masterpassData.pairingVerifier, ignoreNull);
			this._setProperty("postTransactionSentXml", masterpassData.postTransactionSentXml, ignoreNull);
			this._setProperty("preCheckoutData", masterpassData.preCheckoutData, ignoreNull);
			this._setProperty("preCheckoutPreCheckoutAuthHeader", masterpassData.preCheckoutPreCheckoutAuthHeader, ignoreNull);
			this._setProperty("preCheckoutRequestTokenAuthHeader", masterpassData.preCheckoutRequestTokenAuthHeader, ignoreNull);
			this._setProperty("preCheckoutPostShoppingCartAuthHeader", masterpassData.preCheckoutPostShoppingCartAuthHeader, ignoreNull);
			this._setProperty("precheckoutCardId", masterpassData.precheckoutCardId, ignoreNull);
			this._setProperty("precheckoutLoyaltyId", masterpassData.precheckoutLoyaltyId, ignoreNull);
			this._setProperty("precheckoutRequest", masterpassData.precheckoutRequest, ignoreNull);
			this._setProperty("precheckoutResponse", masterpassData.precheckoutResponse, ignoreNull);
			this._setProperty("precheckoutShippingId", masterpassData.precheckoutShippingId, ignoreNull);
			this._setProperty("precheckoutTransactionId", masterpassData.precheckoutTransactionId, ignoreNull);
			this._setProperty("requestToken", masterpassData.requestToken, ignoreNull);
			this._setProperty("requestTokenResponse", masterpassData.requestTokenResponse, ignoreNull);
			this._setProperty("walletPartnerLogoUrl", masterpassData.walletPartnerLogoUrl, ignoreNull);
			this._setProperty("masterpassLogoUrl", masterpassData.masterpassLogoUrl, ignoreNull);
			this._setProperty("rewards", masterpassData.rewards, ignoreNull);
			this._setProperty("shipping", masterpassData.shipping, ignoreNull);
			this._setProperty("shippingId", masterpassData.shippingId, ignoreNull);
			this._setProperty("shoppingCart", masterpassData.shoppingCart, ignoreNull);
			this._setProperty("silentlyPaired", masterpassData.silentlyPaired, ignoreNull);
			this._setProperty("securityOption", masterpassData.securityOption, ignoreNull);
			this._setProperty("transactionId", masterpassData.transactionId, ignoreNull);
			this._setProperty("unpredictableNumber", masterpassData.unpredictableNumber, ignoreNull);
			this._setProperty("verifier", masterpassData.verifier, ignoreNull);
			this._setProperty("walletName", masterpassData.walletName, ignoreNull);
			this._setProperty("xmlVersion", masterpassData.xmlVersion, ignoreNull);

			if(masterpassData.callTraces && masterpassData.callTraces.length){
				traceManager.save(masterpassData.callTraces);
			}

			configurationManager.save();
		},

		setProperties : function(properties, ignoreNull){
			if(properties){
				for(var property in properties){
					this._setProperty(property, properties[property], ignoreNull);
				}
			}
			configurationManager.save();
		},

		_setProperty : function(property, value, ignoreNull){
			if(ignoreNull && value == null){
				// ignoring
			}else{
				configurationManager.state[property] = value;

				// property-specific processing
				if(property == "precheckoutResponse"){
					configurationManager.state.precheckoutResponseDate = new Date();
				}
			}
		},


		setLightboxResponseV7 : function(oauth_token, oauth_verifier, checkout_resource_url){
			// standard checkout response fields
			this._setProperty("requestToken", oauth_token);
			this._setProperty("verifier", oauth_verifier);
			this._setProperty("checkoutResourceURL", checkout_resource_url);
		},

		setLightboxResponse : function(lightboxResponse){
			// standard checkout response fields
			this._setProperty("requestToken", lightboxResponse.oauth_token);
			this._setProperty("verifier", lightboxResponse.oauth_verifier);
			this._setProperty("checkoutResourceURL", lightboxResponse.checkout_resource_url);
			// pair response fields
			this._setProperty("pairingToken", lightboxResponse.pairing_token);;
			this._setProperty("pairingVerifier", lightboxResponse.pairing_verifier);
		},


		/**
		 * Gets a full masterpassData object from memory storage to pass to the backend service layer
		 */
		getMasterpassData : function(cartContainer){
			console.log(cartContainer.cartId);
			var masterpassData = {
				"acceptedCards" : configurationManager.state.acceptedCards,
				"allowedLoyaltyPrograms" : configurationManager.state.allowedLoyaltyPrograms,
				"authLevelBasic" : configurationManager.state.authLevelBasic,
				"callbackDomain" : configurationManager.state.callbackDomain,
				"checkoutCallbackPath" : configurationManager.state.checkoutCallbackPath,
				"callbackUrl" : configurationManager.state.callbackUrl,
				"cardId" : configurationManager.state.cardId,
				"cartId" : cartContainer.cartId,
				"checkoutIdentifier" : configurationManager.state.checkoutIdentifier,
				"checkoutResourceURL" : configurationManager.state.checkoutResourceURL,
				"connectedCallbackPath" : configurationManager.state.connectedCallbackPath,
				"consumerKey" : configurationManager.state.consumerKey,
				"checkoutType" : configurationManager.state.checkoutType,
				"currencyCode" : configurationManager.state.currencyCode,
				"expressCallbackPath" : configurationManager.state.expressCallbackPath,
				"expressCheckoutIndicator" : configurationManager.state.expressCheckoutIndicator,
				"expressSecurityRequired" : configurationManager.state.expressSecurityRequired,
				"longAccessToken" : configurationManager.state.longAccessToken,
				"pairingCallbackPath" : configurationManager.state.pairingCallbackPath,
				"pairingDataTypes" : configurationManager.state.pairingDataTypes,
				"pairingToken" : configurationManager.state.pairingToken,
				"pairingVerifier" : configurationManager.state.pairingVerifier,
				"precheckoutCardId" : configurationManager.state.precheckoutCardId,
				"precheckoutShippingId" : configurationManager.state.precheckoutShippingId,
				"precheckoutLoyaltyId" : configurationManager.state.precheckoutLoyaltyId,
				"precheckoutTransactionId" : configurationManager.state.precheckoutTransactionId,
				"consumerWalletId" : configurationManager.state.consumerWalletId,
				"realm" : configurationManager.state.realm,
				"redirectShippingProfiles" : configurationManager.state.redirectShippingProfiles,
				"requestToken" : configurationManager.state.requestToken,
				"rewards" : configurationManager.state.rewards,
				"shippingId" : configurationManager.state.shippingId,
				"shipping" : Math.round(cartContainer.shipping * 100),
				"shippingProfiles" : configurationManager.state.shippingProfiles,
				"shippingSuppression" : cartContainer.digital == true,
				"silentlyPaired" : configurationManager.state.silentlyPaired,
				"securityOption" : configurationManager.state.securityOption,
				"transactionId" : configurationManager.state.transactionId,
				"tax" : Math.round(cartContainer.tax * 100),
				"unpredictableNumber" : configurationManager.state.unpredictableNumber,
				"walletName" : configurationManager.state.walletName,
				"verifier" : configurationManager.state.verifier,
				"walletPartnerLogoUrl": configurationManager.state.walletPartnerLogoUrl,
				"masterpassLogoUrl": configurationManager.state.masterpassLogoUrl,
				"xmlVersion" : configurationManager.state.xmlVersion,
				"requestURL" : configurationManager.state.requestURL,
				"shoppingCartUrl" : configurationManager.state.shoppingCartUrl,
				"merchantInitUrl" : configurationManager.state.merchantInitUrl,
				"accessURL" : configurationManager.state.accessURL,
				"preCheckoutUrl" : configurationManager.state.preCheckoutUrl,
				"spPrecheckoutUrl" : configurationManager.state.spPrecheckoutUrl,
				"expressCheckoutUrl" : configurationManager.state.expressCheckoutUrl,
				"spExpressCheckoutUrl" : configurationManager.state.spExpressCheckoutUrl,
				"postbackurl" : configurationManager.state.postbackurl,
				"redirectUrl" : configurationManager.state.redirectUrl,
				"lightboxUrl" : configurationManager.state.lightboxUrl

			};

			if(configurationManager.state.privateLabelCards){
				if(masterpassData.acceptedCards){
					masterpassData.acceptedCards += ',' + configurationManager.state.privateLabelCards;
				}else{
					masterpassData.acceptedCards = configurationManager.state.privateLabelCards;
				}
			}

			return masterpassData;
		}
	}

};

appStorageService.factory('masterpassDataService', ['$http', 'traceManager', 'app', 'configurationManager', factory]);
module.exports = appStorageService.name;
