'use strict';

var controller = function ($scope, app, $http, $location, cart, localStorageService, logger, textBundle, userSessionStore, $state) {
	logger('popupCart controller');
	$scope.app = app;
	$scope.cart = cart;
};

module.exports = function (module) {
	var name = 'PopupCartController';
	module.controller(name, controller);

	return name;
};