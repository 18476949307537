'use strict';
var angular = require('angular');
var quickCartService = angular.module('quickCartService', []);

var factory = function (app, localStorageService, $http) {

	var instance = {

		cartContainer : null,
		orderConfirmation : false,
		/**
		 * fetches current cart object 
		 */
		getStoredCart : function(){
			this.cartContainer = localStorageService.get('quickCartContainer');
			if(!this.cartContainer){
				this.clearCart();
			}
		},

		clearCart : function(){
			this.cartContainer = {'products':{}, 'cartCount':0, 'subtotal':0, 'shipping':0, 'tax':0, 'totalPrice':0, 'digital':false};
			this.saveCart();
		},

		addItem : function(){
			var newItem = {
				productId : 'p_' + (new Date()).getTime(),
				name : 'Product',
				price : 25.00,
				cartQuantity : 0
			}
			this.updateCart(newItem, 1, newItem.productId);
		},

		removeItem : function(productId){
			var product = this.cartContainer.products[productId];
			this.updateCart(product, product.cartQuantity * -1, productId);
		},

		updateCart : function(product, quantity, productId){
			var products = this.cartContainer['products'];
			var cartCount = this.cartContainer['cartCount'];
			var subtotal = this.cartContainer['subtotal'];
			// If product already in cart, add to existing quantity
			// Otherwise, value is quantity
 			if(products[productId] != null){
				products[productId].cartQuantity = products[productId].cartQuantity + quantity;
			}
			else {
				// Add passed product data to product list
				products[productId] = product;
				products[productId].cartQuantity = quantity;
			}

			// If less than 0 of given product
			// Remove from cart and product list
			if(products[productId].cartQuantity <= 0){
				delete products[productId];
			}

			// Update local storage cart container with local version
			this.recalculate();
		},

		recalculate : function(){
			var products = this.cartContainer.products;
			var cartCount = 0;
			var subtotal = 0;
			for(var pid in products){
				var product = products[pid];
				cartCount += product.cartQuantity;
				subtotal += product.cartQuantity * product.price;
			}
			this.cartContainer['cartCount'] = cartCount;
			this.cartContainer['subtotal'] = subtotal;
			this.cartContainer['tax'] = subtotal * 0.05;
			this.cartContainer['shipping'] = this.cartContainer.digital ? 0 : 5;
			this.cartContainer['totalPrice'] = subtotal + this.cartContainer['tax'] + this.cartContainer['shipping'];
			this.saveCart();
		},

		saveCart : function(){
			localStorageService.add('quickCartContainer', this.cartContainer);
		}
	};

	//initialize
	instance.getStoredCart();

	if(instance.cartContainer.cartCount == 0){
		instance.addItem();
	}

	return instance;
};

quickCartService.factory('quickCart', ['app', 'localStorageService', '$http', factory]);
module.exports = quickCartService.name;
