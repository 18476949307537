'use strict';
var angular = require('angular');
var categoryService = angular.module('categoryService', []);

var factory = function (app, $http) {
	return {

		categoryPath: [],
		categoryMap: {},
		currentCategory : '',
		pageContent : {},

        setCategoryMap: function() {
			return $http.get(app.serviceBase + '/catalog/search/categories');
		}

	};
};

categoryService.factory('categoryService', ['app',  '$http', factory]);
module.exports = categoryService.name;