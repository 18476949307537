'use strict';
var angular = require('angular');
var appService = angular.module('appService', []);

var factory = function () {
	return {
		title: 'ProGameHub',
		serviceBase : 'https://www.progamehub.com/mtt',
		loading : false,
		showConfiguration : false,
		showTrace : false
	};
};

appService.factory('app', factory);
module.exports = appService.name;
