'use strict';

var controller = function ($scope, $location, $window, app, action, masterpass, masterpassCallbackResponse, checkoutService, cart, logger, paypal, paypalReturnResponse, paypalRestReturnResponse, textBundle, userSessionStore, $state, $stateParams) {
	logger('callback controller');
	/* declaration */

	this.app = app;
	this.cart = cart;
	app.currentPage = $state.current.name;

	if(paypalReturnResponse && paypalRestReturnResponse){
		paypal.doRestPayment(paypalReturnResponse.token, paypalRestReturnResponse.paymentId, paypalRestReturnResponse.PayerID, paypalRestReturnResponse.guid, function(data){
			console.log(data);
			//save data to checkout object
			checkoutService.applyCheckoutPayPalRestResource(data);
		});

	}

	if(paypalReturnResponse && !paypalRestReturnResponse){
		paypal.getExpressCheckoutDetails(paypalReturnResponse.token, paypalReturnResponse.payerID, function(data){
			console.log(data);
			//save data to checkout object
			checkoutService.applyCheckoutPayPalResource(data);
			$state.go('review');
		});
	}

	/* initalization */
	if (masterpassCallbackResponse) {
		console.log("Callback response: %o", JSON.stringify(masterpassCallbackResponse));

		// reload page to clear lightbox
		if('reload' in $location.search()){
			$location.search('reload',null);
			var absUrl = $location.absUrl();
			$window.location.href = absUrl;
			$window.location.reload();
			return;
		}

		var url = new URL(location.protocol + "//" + location.host + location.pathname + location.search);
		if(typeof url.searchParams != 'undefined' && url.searchParams.has('mpstatus')){
			masterpassCallbackResponse.mpstatus = url.searchParams.get('mpstatus');
			masterpassCallbackResponse.oauth_token = url.searchParams.get('oauth_token');
			masterpassCallbackResponse.oauth_verifier = url.searchParams.get('oauth_verifier');
			masterpassCallbackResponse.checkout_resource_url = url.searchParams.get('checkout_resource_url');
			masterpassCallbackResponse.pairing_token = url.searchParams.get('pairing_token');
			masterpassCallbackResponse.pairing_verifier = url.searchParams.get('pairing_verifier');
		}else if(getParameter('mpstatus') != null){
			masterpassCallbackResponse.mpstatus = getParameter('mpstatus');
			masterpassCallbackResponse.oauth_token = getParameter('oauth_token');
			masterpassCallbackResponse.oauth_verifier = getParameter('oauth_verifier');
			masterpassCallbackResponse.checkout_resource_url = decodeURIComponent(getParameter('checkout_resource_url'));
			masterpassCallbackResponse.pairing_token = getParameter('pairing_token');
			masterpassCallbackResponse.pairing_verifier = getParameter('pairing_verifier');
		}

		handleCallback(masterpassCallbackResponse);
	}

	function getParameter(paramName) {
		var searchString = window.location.search.substring(1),
			i, val, params = searchString.split("&");
		for (i = 0; i < params.length; i++) {
			val = params[i].split("=");
			if (val[0] == paramName) {
				return val[1];
			}
		}
		return null;
	}

	/* implementation */
	function handleCallback(masterpassCallbackResponse){
		// always get back masterpassCallbackResponse through params


		if(action == 'pair'){
			masterpass.connectCallback(
				masterpassCallbackResponse,
				function(){
					$state.go('account');
				},
				function(){
					$state.go('account');
				},
				cart.cartContainer
			);
		}

		if(action == 'checkout'){
			masterpass.checkoutCallback(
				masterpassCallbackResponse,
				function(data){
					// initaite checkout callback leading to review page
					logger('checkout success callback');
					checkoutService.masterpassCheckoutCallback(data);
				},
				function(data){
					logger('checkout failure callback');
					checkoutService.masterpassCheckoutFailureCallback(data);
				},
				cart.cartContainer
			);
		}

		if(action == 'return'){
			masterpass.returnCheckoutCallback(
				masterpassCallbackResponse,
				function(data){
					// initate return checkout callback and finish placing order
					logger('return checkout callback');
					checkoutService.masterpassReturnCheckout(data);
				},
				function(data){
					checkoutService.masterpassReturnCheckoutFailure(data);
				},
				cart.cartContainer
			)
		}

		if(action == '3ds'){
			masterpass.express3dsCallback(
				masterpassCallbackResponse,
				function(data){
					// initate 3ds callback to finish placing order
					checkoutService.masterpass3dsCallback(data);
				},
				function(data){
					checkoutService.masterpass3dsFailureCallback(data);
				},
				cart.cartContainer
			);
		}
		// come back from lightbox/masterpass on:
			// v5 checkout -> review page #/callback/checkout
				// mpstatus
				// oauth_token (=requestToken);
				// oauth_verifier (=verifier)
			// pairing -> account page #/callback/pair
				// mpstatus
				// pairing_token
				// pairing_verifier
			// checkout (possibly with pairing) -> review page #/callback/checkout
				// mpstatus
				// oauth_token 
				// oauth_verifier 
				// checkout_resource_url
				// pairing_token
				// pairing_verifier
			// return checkout -> receipt page #/callback/return
				// mpstatus
				// oauth_token 
				// oauth_verifier 
				// checkout_resource_url ?
			// 3ds -> receipt #/callback/3ds
				// mpstatus

	}
	$scope.status = 'ready';
};

module.exports = function (module) {
	var name = 'CallbackController';
	module.controller(name, controller);

	return name;
};