'use strict';
var angular = require('angular');
var traceManagerService = angular.module('traceManagerService', []);

var factory = function (localStorage, app) {

	var storedTraceData = localStorage.get('traceData') || { callTraces : [], enabled : true };

	return {
		callTraces : storedTraceData.callTraces,

		enabled : storedTraceData.enabled,

		enable : function(){
			this.enabled = true;
			this.save();
		},

		disable : function(){
			this.enabled = false;
			this.clear();
		},

		save : function(traces){
			if(this.enabled){
				if(traces && traces.length > 0){
					this.callTraces = this.callTraces.concat(traces);
				}
			}
			var dataToStore = {callTraces : this.callTraces, enabled : this.enabled };
			localStorage.add('traceData', dataToStore);
		},

		clear : function(){
			this.callTraces = [];
			this.save();
		}
	}

};

traceManagerService.factory('traceManager', ['localStorageService', 'app', factory]);
module.exports = traceManagerService.name;
