'use strict';
var angular = require('angular');
var paypalService = angular.module('paypalService', []);

var factory = function (app, configurationManager, localStorageService, $http) {
	return {
		startExpressCheckout : function(cartContainer, successCallback){
			var self = this;

			var requestUrl = "https://www.progamehub.com/mtt/PayPal/paypalStart"

			var requestBody = self._getShoppingCart(cartContainer);
			app.loading = true;
			$http.post(requestUrl, requestBody).then(
				function(successResponse){
					console.log(successResponse);
					var redirectUrl = successResponse.data.redirectURL;
					successCallback(redirectUrl);
				},
				function(failureResponse){
					console.log(failureResponse)
				}
			);


		},
		startRestCheckout : function(cartContainer, successCallback){
			var self = this;

			var requestUrl = "https://www.progamehub.com/mtt/PayPal/paypalRestStart"

			var requestBody = self._getShoppingCart(cartContainer);
			app.loading = true;
			$http.post(requestUrl, requestBody).then(
				function(successResponse){
					console.log(successResponse);
					var redirectUrl = successResponse.data.redirectURL;
					successCallback(redirectUrl);
				},
				function(failureResponse){
					console.log(failureResponse)
				}
			);


		},
		getExpressCheckoutDetails : function(token, payerId, successCallback){
			var self = this;

			var requestUrl = "https://www.progamehub.com/mtt/PayPal/getDetails"
			var requestParameters = {};
			requestParameters.token = token;
			requestParameters.payerId = payerId;
			app.loading = true;
			$http.get(requestUrl, { params : requestParameters }).then(
				function(successResponse){
					// checkout data retreived. initiate success callback
					app.loading = false;
					successCallback(successResponse.data);
				},
				function(failureResponse){
					console.log(failureResponse)
				}
			);
		},

		doExpressCheckoutPayment : function(token, payerId, cartContainer, successCallback){
			var self = this;

			var requestUrl = "https://www.progamehub.com/mtt/PayPal/doPayment"
			var requestParameters = {};
			requestParameters.token = token;
			requestParameters.payerId = payerId;
			var requestBody = self._getShoppingCart(cartContainer);

			$http.post(requestUrl, requestBody, { params : requestParameters }).then(
				function(successResponse){
					// checkout data retreived. initiate success callback
					app.loading = false;
					successCallback(successResponse.data);
				},
				function(failureResponse){
					console.log(failureResponse)
				}
			);
		},

		doRestPayment : function(token, paymentId, payerId, guid, successCallback){
			var self = this;

			var requestUrl = "https://www.progamehub.com/mtt/PayPal/executePayment"
			var requestParameters = {};
			requestParameters.token = token;
			requestParameters.payerId = payerId;
			requestParameters.paymentId = paymentId;
			requestParameters.guid = guid;

			$http.get(requestUrl, { params : requestParameters }).then(
				function(successResponse){
					// checkout data retreived. initiate success callback
					app.loading = false;
					successCallback(successResponse.data);
				},
				function(failureResponse){
					console.log(failureResponse)
				}
			);
		},
		/**
		 * Creates a ShoppingCartShoppingCartRequest object
		 */
		_getShoppingCart : function(cartContainer){
			var cartRequest = {
					"shoppingCartItems" : [],
					"currencyCode": configurationManager.state.currencyCode,
					"subtotal": (cartContainer.subtotal).toFixed(2),
					"shipping": (cartContainer.shipping).toFixed(2),
					"taxes": (cartContainer.tax).toFixed(2),
					"total": (cartContainer.totalPrice).toFixed(2),
					"digital": cartContainer.digital,
			};

			// populate 
			var products = cartContainer.products;
			for(var id in products){
				var product = products[id];
				if(product && product.productId){
					var price = product.price;
					var itemValue = (price * product.cartQuantity).toFixed(2);

					var shoppingCartItem = {
						"description": product.name,
						"quantity":product.cartQuantity,
						"value": itemValue,
						"digital": product.digital
					}
					if(product.image){
						shoppingCartItem.imageURL = product.image;
					}

					cartRequest.shoppingCartItems.push(shoppingCartItem);
				}
			}

			return cartRequest;
		}
	};
};

paypalService.factory('paypal', ['app', 'configurationManager', 'localStorageService', '$http', factory]);
module.exports = paypalService.name;