'use strict';

var controller = function ($scope, categoryService, configurationManager, localListManager, logger, masterpass, app, textBundle, quickCart, $state, $window) {
	logger('configuration controller');

	/* declaration */

	$scope.app = app;

	$scope.state = {};

	$scope.configurationManager = configurationManager;

	$scope.toggle = toggle;

	$scope.save = configurationManager.save.bind(configurationManager);

	$scope.clear = configurationManager.clear.bind(configurationManager);

	$scope.setPage = setPage;

	$scope.openTrace = openTrace;

	$scope.toggleCardSelection = toggleCardSelection;

	$scope.togglePairingTypeSelection = togglePairingTypeSelection;

	$scope.buyButton = buyButton;

	$scope.connectButton = connectButton;

	$scope.getPrecheckoutData = getPrecheckoutData;

	$scope.clearPairingInformation = clearPairingInformation;

	$scope.changeEnvironment = changeEnvironment;

	$scope.reloadJavascriptEnvironment = reloadJavascriptEnvironment;

	$scope.reset = reset;

	$scope.updateReturnCheckoutButton = updateReturnCheckoutButton;

	$scope.showGeneratedBuyButton = showGeneratedBuyButton;

	$scope.generateDisplayAddress = generateDisplayAddress;

	$scope.generateDisplayCard = generateDisplayCard;

	$scope.generateDisplayReward = generateDisplayReward;

	$scope.quickCart = quickCart;

	$scope.localListManager = localListManager;

	$scope.shippingLocationProfilesArray = [];

	$scope.initalizeShippingLocationProfiles = initalizeShippingLocationProfiles;

	$scope.toggleShippingLocationProfileSelection = toggleShippingLocationProfileSelection;

	$scope.removeShippingLocationProfileSelection = removeShippingLocationProfileSelection;

	$scope.loyaltyProgramsArray = [];

	$scope.initalizeLoyaltyPrograms = initalizeLoyaltyPrograms;

	$scope.toggleLoyaltyProgramSelection = toggleLoyaltyProgramSelection;

	$scope.removeLoyaltyProgramSelection = removeLoyaltyProgramSelection;

	$scope.longAccessTokenUpdated = longAccessTokenUpdated;


	/* initalization */

	initalizeShippingLocationProfiles();

	initalizeLoyaltyPrograms();

	// watch opening of configuration screen to create return checkout button
	$scope.$watch(
		function watchShowConfiguration(scope){
			return app.showConfiguration;
		},
		function handleShowConfigurationChange(newValue, oldValue){
			console.log("View changing from %s to %s", oldValue, newValue);
			if(newValue == true){
				updateReturnCheckoutButton();
			}
		}
	);



	/* implementation */

	function longAccessTokenUpdated(){
		console.log('longAccessTokenUpdated');
		this.getPrecheckoutData();
	}

	function initalizeShippingLocationProfiles(){
		if(configurationManager.state.shippingProfiles){
			$scope.shippingLocationProfilesArray = configurationManager.state.shippingProfiles.split(',');
		}else{
			$scope.shippingLocationProfilesArray = [];
		}
		$scope.localListManager.merge('shippingLocationProfiles', $scope.shippingLocationProfilesArray);
		configurationManager.save();
	}

	function toggleShippingLocationProfileSelection(profile){
		var index = $scope.shippingLocationProfilesArray.indexOf(profile);
		if(index > -1){
			// remove from array
			$scope.shippingLocationProfilesArray.splice(index, 1);
		}else{
			// add to array
			$scope.shippingLocationProfilesArray.push(profile);
		}
		// update shippingProfiles according to array
		configurationManager.state.shippingProfiles = $scope.shippingLocationProfilesArray.join(',');
		// update list
		$scope.localListManager.merge('shippingLocationProfiles', $scope.shippingLocationProfilesArray)
		configurationManager.save();
	}

	function removeShippingLocationProfileSelection(profile){
		var index = $scope.shippingLocationProfilesArray.indexOf(profile);
		if(index > -1){
			// remove from array
			$scope.shippingLocationProfilesArray.splice(index, 1);
		}
		$scope.localListManager.remove('shippingLocationProfiles', profile);
		configurationManager.state.shippingProfiles = $scope.shippingLocationProfilesArray.join(',');
		configurationManager.save();
	}

	function initalizeLoyaltyPrograms(){
		if(configurationManager.state.allowedLoyaltyPrograms){
			$scope.loyaltyProgramsArray = configurationManager.state.allowedLoyaltyPrograms.split(',');
		}else{
			$scope.loyaltyProgramsArray = [];
		}
		$scope.localListManager.merge('loyaltyPrograms', $scope.loyaltyProgramsArray);
		configurationManager.save();
	}

	function toggleLoyaltyProgramSelection(profile){
		var index = $scope.loyaltyProgramsArray.indexOf(profile);
		if(index > -1){
			// remove from array
			$scope.loyaltyProgramsArray.splice(index, 1);
		}else{
			// add to array
			$scope.loyaltyProgramsArray.push(profile);
		}
		// update allowedLoyaltyPrograms according to array
		configurationManager.state.allowedLoyaltyPrograms = $scope.loyaltyProgramsArray.join(',');
		// update list
		$scope.localListManager.merge('loyaltyPrograms', $scope.loyaltyProgramsArray)
		configurationManager.save();
	}

	function removeLoyaltyProgramSelection(profile){
		var index = $scope.loyaltyProgramsArray.indexOf(profile);
		if(index > -1){
			// remove from array
			$scope.loyaltyProgramsArray.splice(index, 1);
		}
		$scope.localListManager.remove('loyaltyPrograms', profile);
		configurationManager.state.allowedLoyaltyPrograms = $scope.loyaltyProgramsArray.join(',');
		configurationManager.save();
	}

	function toggle(){
		app.showConfiguration = !app.showConfiguration;
		if(app.showConfiguration == false){
			$scope.save();
		}
	}

	function setPage(page){
		configurationManager.page = page;
	}

	function openTrace(){
		app.showConfiguration = false;
		$scope.save();

		app.showTrace = true;
	}

	function toggleCardSelection(cardId) {
		var index = configurationManager.state.acceptedCards.indexOf(cardId);
		if (index > -1) {
			configurationManager.state.acceptedCards.splice(index, 1);
		}else {
			configurationManager.state.acceptedCards.push(cardId);
		}
		configurationManager.save();
	}

	function togglePairingTypeSelection(type) {
		var index = configurationManager.state.pairingDataTypes.indexOf(type);
		if (index > -1) {
			configurationManager.state.pairingDataTypes.splice(index, 1);
		}else {
			configurationManager.state.pairingDataTypes.push(type);
		}
		configurationManager.save();
	}

	function connectButton(){
		masterpass.connect(
			function(){
				masterpass.precheckoutData(
					masterpassCallback,
					quickCart.cartContainer
				);
			},
			masterpassCallback,
			quickCart.cartContainer
		);
	}

	function buyButton(){
		configurationManager.save();
		if(configurationManager.state.checkoutType == masterpass.CHECKOUT_TYPE.EXPRESS && configurationManager.state.longAccessToken){
			if(configurationManager.state.preCheckoutData){
				// perform express checkout
				masterpass.expressCheckout(
					masterpassCallback,
					masterpassCallback,
					quickCart.cartContainer
				);
			}else{
				// warn to retreive precheckout data
				$window.alert('Please retrieve Pre-Checkout Data to proceed with Express Checkout or remove the Long Access Token.');
			}
		}else{
			// perform standard checkout
			masterpass.checkout(
				masterpassCallback,
				masterpassCallback,
				quickCart.cartContainer
			);
		}
	}

	function getPrecheckoutData(){
		configurationManager.save();
		if(configurationManager.state.longAccessToken){
			masterpass.precheckoutData(
				function(data){
					configurationManager.masterpassCallback(data);
					updateReturnCheckoutButton();
				},
				quickCart.cartContainer
			);
		}
	}

	function clearPairingInformation(){
		masterpass.clearPairingData();
		masterpass.clearCheckoutData();
	}

	function changeEnvironment(){
		app.loading = true;
		configurationManager.loadEndpointEnvironment(
			configurationManager.state.environment,
			function(){
				app.loading = false;
			}
		);
	}

	function reloadJavascriptEnvironment(){
		configurationManager.reloadJavascriptEnvironment();
	}

	function reset(){
		app.loading = true;
		configurationManager.reset(function(){
			initalizeShippingLocationProfiles();
			initalizeLoyaltyPrograms();
			app.loading = false;
		});
	}

	function updateReturnCheckoutButton(){
		var container = document.querySelector('#config_mp_button');
		if(container){
			container.innerHTML = '';
		}

		if(showGeneratedBuyButton()){
			masterpass.returnCheckout(
				'config_mp_button',
				masterpassCallback,
				masterpassCallback,
				quickCart.cartContainer
			);
		}
	}

	function masterpassCallback(response){
		configurationManager.masterpassCallback(response);
		if(!response){
			masterpass.clearCheckoutData();
		}
		updateReturnCheckoutButton();
	}

	function showGeneratedBuyButton(){
		return configurationManager.state.checkoutType == masterpass.CHECKOUT_TYPE.CONNECT && configurationManager.state.preCheckoutData
	}


	function generateDisplayAddress(address){
		var displayString = '';

		displayString += address.line1;
		// address.recipientName
		if(address.line2){
			displayString += ', ' + address.line2;
		}
		if(address.line3){
			displayString += ', ' + address.line3;
		}
		if(address.city){
			displayString += ', ' + address.city;
		}
		if(address.countrySubdivision){
			var arr = address.countrySubdivision.split('-');
			displayString += ', ' + arr[arr.length-1];
		}
		if(!address.city || !address.countrySubdivision){
			displayString += ', ' + address.country;
		}
		if(address.postalCode){
			displayString += ' ' + address.postalCode;
		}
		if(address.city && address.countrySubdivision){
			displayString += ' ' + address.country;
		}
		return displayString;
	}

	function generateDisplayCard(card){
		return (card.brandName == null ? '' : card.brandName) + ' ***-' + card.lastFour;
	}

	function generateDisplayReward(reward){
		return reward.rewardName;
	}


};

module.exports = function (module) {
	var name = 'ConfigurationController';
	module.controller(name, controller);

	return name;
};
