'use strict';

var controller = function ($scope, app, $http, checkoutService, $location, cart, localStorageService, logger, textBundle, userSessionStore, $state) {
	logger('navbar controller');
	this.app = app;
	this.cart = cart;
	this.state = $state;
	this.dataTarget = '';

	this.startCheckout = startCheckout;

	this.setHover = setHover;

	this.expandBody = expandBody;

	$scope.toggleSearchBar = toggleSearchBar;

	$scope.toggleMenu = toggleMenu;

	this.setDataTarget = setDataTarget;

	/* initalization */


	/* implementation */

	function toggleSearchBar(){
		if($('.searchbar').hasClass('hidden')){
			$('.searchbar').removeClass('hidden');
			$('.headerContainer').addClass('moreHeight');
		}else{
			$('.searchbar').addClass('hidden');
			$('.headerContainer').removeClass('moreHeight');
		}
	}

	function toggleMenu(){
		if($('#topMenu1').hasClass('mobileHidden')){
			$('#topMenu1').removeClass('mobileHidden');
		}else{
			$('#topMenu1').addClass('mobileHidden');
		}
	}

	function expandBody() {
		if($('body').hasClass('expand')){
			$('body').removeClass('expand');
			$('#sidebar1').removeClass('expand');
			$('#sidebar2').removeClass('expand');
			$('.searchBar').blur();
		}
		else {
			$('body').addClass('expand');
			$('#sidebar1').addClass('expand');
			$('#sidebar2').addClass('expand');
			$('.searchBar').focus();
		}
	}

	function setHover(bool) {
		// Executed when mouse hovers over cart link in navbar, triggers popup cart animation
		this.cart.cartPopup = bool;
	};

	function startCheckout(){
		checkoutService.startCheckout();
	}

	function setDataTarget(){
		if($('#sidebar1').hasClass('in')){
			this.dataTarget = '#sidebar1';
		}
		else {
			this.dataTarget = '';
		}
	}
};

module.exports = function (module) {
	var name = 'NavbarController';
	module.controller(name, controller);

	return name;
};