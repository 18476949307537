'use strict';
var angular = require('angular');
var configurationManagerService = angular.module('configurationManagerService', []);

var factory = function (localStorage, app, $http, $window) {

	var storedConfigurationData = localStorage.get('configurationData') || { state : {}, availableConfigurations : {} };

	// statically defined configuration properties
	//storedConfigurationData.state.callbackDomain = window.location.protocol + "//localhost:9000";
	
	storedConfigurationData.state.callbackDomain = window.location.protocol + "//www.progamehub.com";
	storedConfigurationData.state.checkoutCallbackPath = window.location.pathname + "#/callback/checkout?reload=true";
	storedConfigurationData.state.connectedCallbackPath = window.location.pathname + "#/callback/return?reload=true";
	storedConfigurationData.state.pairingCallbackPath = window.location.pathname + "#/callback/pair?reload=true";
	storedConfigurationData.state.expressCallbackPath = window.location.pathname  + "#/callback/3ds?reload=true";

	storedConfigurationData.state.realm = "eWallet";
	storedConfigurationData.state.currencyCode = 'USD';
	storedConfigurationData.state.shippingSuppression = false;

	var instance = {
		state : storedConfigurationData.state,

		availableConfigurations : storedConfigurationData.availableConfigurations,

		constants : {
			VERSIONS : [
				"v5",
				"v7"
			],
			PAIRING_DATA_TYPES : [
				"PROFILE",
				"ADDRESS",
				"REWARD_PROGRAM",
				"CARD"
			],
			PAIRING_TYPES : [
				"Regular",
				"Always"
			],
			CHECKOUT_TYPES : [
				"Standard",
				"Connect",
				"Express"
			],
			CREDIT_CARDS : [
				"master",
				"amex",
				"diners",
				"discover",
				"maestro",
				"visa"
			],
			CURRENCY : [
				"USD"
			],
			LANGUAGE : [
				'English'
			]
		},

		page : 'menu',

		save : function(){
			var dataToStore = {state : this.state};
			localStorage.add('configurationData', dataToStore);
		},

		clear : function(){
			this.state = [];
			this.save();
		},

		getEndpointEnvironments : function(callback){
			var self = this;
			// load available endpoint configurations
			$http.get(app.serviceBase + '/configuration/getEnvironments').then(
				function(response){
					if(!self.availableConfigurations){
						self.availableConfigurations = {};
					}
					self.availableConfigurations.environments = response.data;
					if(callback){
						callback(response.data);
					}
				},
				function(response){
					console.error('unable to retrieve available environments');
					if(callback){
						callback(null);
					}
				}
			);
		},

		loadEndpointEnvironment : function(env, callback){
			var self = this;

			var requestParameters = { "environment" : env };
			var requestUrl = app.serviceBase + '/configuration/getEnvironment';
			$http.get(requestUrl, { "params" : requestParameters }).then(
				function(response){
					if(response.data && response.data.endpoints){
						self.setEndpointConfiguration(response.data.endpoints);
					}else{
						console.error('unable to retrieve available endpoint environment - empty response: %s', env);
					}
					if(callback){
						callback(response.data);
					}
				},
				function(response){
					console.error('unable to retrieve available endpoint environment: %s', env);
					if(callback){
						callback(null);
					}
				}
			);
		},

		getCheckoutIdentifiers : function(callback){
			var self = this;
			// load available endpoint configurations
			$http.get(app.serviceBase + '/configuration/getCheckoutIds').then(
				function(response){
					if(!self.availableConfigurations){
						self.availableConfigurations = {};
					}
					self.availableConfigurations.checkoutIdentifiers = response.data;
					if(callback){
						callback(response.data);
					}
				},
				function(response){
					console.error('unable to retrieve available checkoutIdentifiers');
					if(callback){
						callback(null);
					}
				}
			);
		},

		getConsumerKeys : function(callback){
			var self = this;
			// load available endpoint configurations
			$http.get(app.serviceBase + '/configuration/getConsumerKeys').then(
				function(response){
					if(!self.availableConfigurations){
						self.availableConfigurations = {};
					}
					self.availableConfigurations.consumerKeys = response.data;
					if(callback){
						callback(response.data);
					}
				},
				function(response){
					console.error('unable to retrieve available consumerKeys');
					if(callback){
						callback(null);
					}
				}
			);
		},

		loadConfiguration : function(callback){
			var self = this;
			// load default configuration from server
			this.state.syncing = true;
			$http.get(app.serviceBase + '/configuration/getDefault').then(
				function(response){
					// map configuration to local configuration object

					// endpoint configuration
					self.setEndpointConfiguration(response.data.endpoints);

					// MasterpassConfiguration
					self.setMasterpassConfiguration(response.data.masterpass);

					// checkout Identifier
					self.setCheckoutIdentifier(response.data.checkoutId);

					// consumer key
					self.setConsumerKey(response.data.consumerKey);

					self.state.configurationLoaded = true;
					self.state.syncing = false;

					self.save();
					if(callback){
						callback(response.data);
					}
				},
				function(response){
					self.state.syncing = false;
					console.error("Unable to get default configuration.");
					$window.alert("Unable to retrieve default configuration.");
					if(callback){
						callback(null);
					}
				}
			);
		},

		setEndpointConfiguration : function(endpointConfig){
			this.state.environment = endpointConfig.id;
			this.state.requestURL = endpointConfig.requestTokenEndpoint;
			this.state.shoppingCartUrl = endpointConfig.shoppingCartEndpoint;
			this.state.merchantInitUrl = endpointConfig.merchantInitializationEndpoint;
			this.state.accessURL = endpointConfig.accessTokenEndpoint;
			this.state.preCheckoutUrl = endpointConfig.precheckoutDataEndpoint;
			this.state.spPrecheckoutUrl = endpointConfig.spPrecheckoutUrl;
			this.state.expressCheckoutUrl = endpointConfig.expressCheckoutEndpoint;
			this.state.spExpressCheckoutUrl = endpointConfig.spExpressCheckoutEndpoint;
			this.state.postbackurl = endpointConfig.postbackTransactionEndpoint;
			this.state.redirectUrl = endpointConfig.masterpassRedirectUrl;
			this.state.lightboxUrl = endpointConfig.lightboxJavascriptUrl;

			// reload new lightbox script
			this.reloadJavascriptEnvironment();
		},

		setMasterpassConfiguration : function(masterpassConfig){
			this.state.checkoutIdAlias = masterpassConfig.checkoutIdAlias;
			this.state.consumerKey = masterpassConfig.consumerKey;
			this.state.xmlVersion = masterpassConfig.masterpassVersion;
			this.state.authLevelBasic = masterpassConfig.advancedCheckoutOverride;
			this.state.checkoutType = masterpassConfig.checkoutType;
			this.state.pairingType = masterpassConfig.pairingType;
			this.state.showWalletSelection = masterpassConfig.showWalletSelection;
			this.state.pairingDataTypes = masterpassConfig.requestedDataTypes;
			this.state.acceptedCards = masterpassConfig.acceptedCardTypes;
			this.state.privateLabelCardTypes = masterpassConfig.privateLabelCardTypes;
			this.state.enableLoyaltyPrograms = masterpassConfig.enableLoyaltyPrograms;
			this.state.allowedLoyaltyPrograms = masterpassConfig.allowedLoyaltyPrograms;
			this.state.genericErrorMessage = masterpassConfig.genericErrorMessage;
			this.state.callbackDomainOverride = masterpassConfig.callbackDomainOverride;
			this.state.secondaryCallbackOverride = masterpassConfig.secondaryCallbackOverride;
			this.state.shippingProfiles = masterpassConfig.shippingLocationProfiles;
			//masterpassConfig.endpointEnvironment
		},

		setCheckoutIdentifier : function(checkoutIdentifier){
			this.state.checkoutIdAlias = checkoutIdentifier.alias;
			this.state.checkoutIdentifier = checkoutIdentifier.checkoutId;
		},

		setConsumerKey : function(consumerKey){
			this.state.consumerKeyAlias = consumerKey.alias;
			this.state.consumerKey = consumerKey.consumerKey;
		},

		reset : function(callback){
			this.loadConfiguration(callback);
		},

		masterpassCallback : function(data){
			app.loading = false;
			app.showTrace = false;
			app.showConfiguration = true;
			this.page = "callback";
			if(data == null){
				$window.alert('Request Failed');
			}
		},

		reloadJavascriptEnvironment : function(){
			var self = this;

			var currentScript = document.querySelector( '#javascriptEnvironment' );
			if(currentScript && currentScript.getAttribute('data-src') == this.state.lightboxUrl){
				// don't reload same script
				return;
			}else{
				if(currentScript){
					currentScript.remove();
				}

				// load new script
				var done = false;
				var scr = document.createElement('script');

				var handleLoad = function() {
					if (!done) {
						done = true;
						self.state.lightboxUrlLoaded = true;
					}
				}

				var handleReadyStateChange = function() {
					var state;

					if (!done) {
						state = scr.readyState;
						if (state === "complete") {
							handleLoad();
						}
					}
				}

				var handleError = function() {
					if (!done) {
						done = true;
						self.state.lightboxUrlLoaded = true;
						$window.alert('Error loading Lightbox Javascript.  Check the Lightbox URL or reload the page.');
					}
				}

				scr.id = 'javascriptEnvironment';
				scr.setAttribute('data-src', this.state.lightboxUrl);
				scr.onload = handleLoad;
				scr.onreadystatechange = handleReadyStateChange;
				scr.onerror = handleError;
				scr.src = this.state.lightboxUrl + (this.state.lightboxUrl.indexOf('?') > -1 ? '&' : '?') + 'ts=' + (new Date()).getTime();
				document.body.appendChild(scr);
				this.state.lightboxUrlLoaded = false;
			}
		}
	};

	if(!instance.state.configurationLoaded){
		instance.loadConfiguration();
	}

	instance.getEndpointEnvironments();

	instance.getCheckoutIdentifiers();

	instance.getConsumerKeys();

	if(instance.state.configurationLoaded){
		instance.reloadJavascriptEnvironment();
	}

	return instance;
};

configurationManagerService.factory('configurationManager', ['localStorageService', 'app', '$http', '$window', factory]);
module.exports = configurationManagerService.name;
