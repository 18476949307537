'use strict';

var controller = function ($scope, app, cart, configurationManager, masterpass, accountService, $http, $location, logger, textBundle, userSessionStore, $state) {

	/* declaration */

	logger('account controller');
	this.app = app;
	app.currentPage = $state.current.name;

	this.isLoggedIn = accountService.isLoggedIn.bind(accountService);

	this.pairAccount = pairAccount;
	this.pairSuccess = false;
	this.isAccountPaired = isAccountPaired;

	this.email = "jsmith@mc.com";
	this.password = "";
	this.showError = false;

	this.signIn = signIn;
	this.signOut = signOut;



	/* initialization */

	validateLongAccessToken();



	/* implementation */

	function isAccountPaired(){
		if(configurationManager.state.longAccessToken){
			return true;
		}else{
			return false;
		}
	}

	function pairAccount(){
		app.loading = true;
		masterpass.connect(
			function(){
				app.loading = false;
				validateLongAccessToken();

			},
			function(){
				app.loading = false;
			},
			cart.cartContainer
		);
	}

	function signIn(){
		// Show profile content if logged in, otherwise show log in page
		if(this.password){
			var loginSuccess = accountService.login(this.email);
			if(loginSuccess){
				this.showError = false;

				// trigger precheckout data request if longAccessToken is present
				validateLongAccessToken();

			}else{
				this.showError = true;
			}
		}
		else {
			this.showError = true;
		}
	}

	function signOut(){
		accountService.logout();
	}

	function validateLongAccessToken(){
		if(accountService.isLoggedIn() && configurationManager.state.longAccessToken){
			masterpass.precheckoutData(
				function(response){
					app.loading = false;
					console.log("accountService Login precheckoutData: %o", response);
				},
				cart.cartContainer
			);
		}
	}
	$scope.status = 'ready';
};

module.exports = function (module) {
	var name = 'AccountController';
	module.controller(name, controller);

	return name;
};