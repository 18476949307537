'use strict';

var controller = function ($scope, app, facets, categoryService, $window, $http, $location, logger, textBundle, userSessionStore, $state, $stateParams) {
	logger('products controller');
	$scope.app = app;
	app.currentPage = $state.current.name;
	$scope.stateParams = $stateParams;
	$scope.state = $state;
	if($stateParams.facets){
		$scope.propertyList = $stateParams.facets.split(',');
	}
	else {
		$scope.propertyList = [];
	}
	$scope.sort = $stateParams.sort;
	$scope.facets = facets;

	console.log("state params: %o", $stateParams);

	$scope.filterFunctions = function() {
		// Slide in animation for filter sidebar
		if($('.refine').hasClass('outside')){
			$('.refine').toggleClass('inside');
			$('.refine').toggleClass('outside');
			setTimeout(function() {
				$('.sidebar2-offcanvas').toggleClass('active');
				$('.productList').toggleClass('compressed');
				$('.refine').hide();
			}, 250);
		}
		else if($('.refine').hasClass('inside')) {
			$('.sidebar2-offcanvas').toggleClass('active');
			$('.productList').toggleClass('compressed');
			$('.refine').show();
			setTimeout(function() {
				$('.refine').toggleClass('inside');
				$('.refine').toggleClass('outside');
			}, 125);
		}
	};

	$scope.showProperies = "";
	$scope.toggleProperties = function(facet){
		// Set show properties to clicked facet
		// This displays all filters related to that facet
		if($scope.showProperties == facet){
			$scope.showProperties = "";
		}
		else {
			$scope.showProperties = facet;
		}
	};

	$scope.addOrRemoveProperty = function(key, property){
		// Check if property in list of facets which are currently being used

		console.log("state params: %o", $stateParams);

		var propertyFull = key + ":" + property;
		if($scope.propertyList.indexOf(propertyFull) == -1){
			$scope.propertyList.push(propertyFull);
		}
		else {
			$scope.propertyList.splice($scope.propertyList.indexOf(propertyFull), 1);
		}
	};

	$scope.checkProperties = function(key, property){
		// Check if property in list of facets which are currently being used
		var propertyFull = key + ":" + property;
		if($scope.propertyList.indexOf(propertyFull) == -1){
			return false;
		}
		else {
			return true;
		}
	};

	$scope.setSort = function(sort){
		if($scope.sort == sort){
			if($scope.sort.split(',')[1] == 'desc'){
				$scope.sort = $scope.sort.split(',')[0];
			}
			else {
				$scope.sort = sort + ',desc';
			}
		}
		else {
			$scope.sort = sort;
		}
	};

	$scope.scale = 'medium';

	$scope.resize = function(itemContainerClass, heightAdjustments){
		$('.itemContainer').addClass(itemContainerClass[0]).addClass(heightAdjustments[0]);
		$('.itemContainer').removeClass(itemContainerClass[1]).removeClass(itemContainerClass[2]);
		$('.itemContainer').removeClass(heightAdjustments[1]).removeClass(heightAdjustments[2]);
		$scope.scale = heightAdjustments[0];
	};
	$scope.pagestatus = 'ready';
};

module.exports = function (module) {
	var name = 'ProductsController';
	module.controller(name, controller);

	return name;
};