'use strict';

var controller = function ($scope, categoryService, traceManager, logger, app, textBundle, $state) {
	logger('trace controller');

	/* declaration */

	$scope.app = app;

	$scope.stopPropagation = stopPropagation;

	$scope.traceManager = traceManager;

	$scope.toggle = toggle;

	$scope.isEnabled = isEnabled;

	$scope.enable = traceManager.enable.bind(traceManager);

	$scope.disable = traceManager.disable.bind(traceManager);

	$scope.clear = traceManager.clear.bind(traceManager);

	$scope.openConfiguration = openConfiguration;




	/* initalization */




	/* implementation */

	function isEnabled(){
		return traceManager.enabled;
	}

	function toggle(){
		app.showTrace = !app.showTrace;
	}

	function stopPropagation($event){
		$event.stopPropagation();
	}

	function openConfiguration(){
		app.showTrace = false;

		app.showConfiguration = true;
	}
};

module.exports = function (module) {
	var name = 'TraceController';
	module.controller(name, controller);

	return name;
};
