'use strict';
var angular = require('angular');
var visaService = angular.module('visaService', []);

var factory = function (app, localStorageService, $http, cart) {
	return {
		generatePixel : function(data, callid, successCallback){
			var requestUrl = "https://www.progamehub.com/mtt/VisaCheckout/generatePixelUrl"

			var requestPayload = {};
			requestPayload.total = data.cartContainer.totalPrice;
			requestPayload.subtotal = data.cartContainer.subtotal;
			requestPayload.callid = callid;
			$http.post(requestUrl, requestPayload).then(
				function(successResponse){
					console.log(successResponse);
					var visaPixel = successResponse.data.pixelUrl;
					successCallback(visaPixel);
				},
				function(failureResponse){
					console.log(failureResponse)
				}
			);

		}
	};
};

visaService.factory('visa', ['app', 'localStorageService', '$http', 'cart', factory]);
module.exports = visaService.name;