'use strict';

var controller = function ($scope, $window, app, accountService, braintreeService, simplifyService, cart, masterpass, $http, $location, checkoutService, localStorageService, logger, textBundle, userSessionStore, $state) {
	logger('checkout controller');
	this.app = app;
	app.currentPage = $state.current.name;
	var braintreeTimeout;
	var self = this;

	// Retrieve any existing checkout info
	this.info = checkoutService.getInfo();

	this.isLoggedIn = accountService.isLoggedIn.bind(accountService);

	if(this.isLoggedIn()){
		//set billing/payment here
		this.info['fullName'] = 'Srinivasarao Nandiwada';
		this.info['email'] = 'nsr@unboundcommerce.com';
		this.info['billingAddress1'] = '88 Oak Street';
		this.info['phone'] = '8665037005';
		this.info['billingZipcode'] = '02464';
		this.info['billingState'] = 'MA';
		this.info['billingCity'] = 'Newton';
	}
	this.review = review;
	this.masterpass = masterpass;

	// Regexes used to check user input
	this.nameRegex = /^[a-z]([-']?[a-z]+)*( [a-z]([-']?[a-z]+)*)+$/i;
	this.emailRegex = /\S+@\S+\.\S+/;
	this.addressRegex = /^[a-zA-Z0-9\s\,\''\-]*$/;
	this.phoneNumberRegex = /^[0-9]{10,}/;
	this.creditCardRegex = /(?:\d[ -]?){12,18}\d/;
	this.zipcodeRegex = /^\b\d{5}(-\d{4})?\b$/;
	this.cvvRegex = /^[0-9]{3,4}$/;
	this.isDigital = cart.cartContainer.digital;

	this.years = [];


	$window.scrollTo(0,0);



	/* initialization */

	// If cart is empty, redirect to empty cart
	if(cart.cartContainer['cartCount'] == 0){
		$state.go('cart');
	}

	// Populate year list for year dropdown
	var currentTime = new Date();
	var minYear = currentTime.getFullYear() + 1;
	var maxYear = currentTime.getFullYear() + 25;
	for(minYear; minYear < maxYear; minYear++){
		this.years.push(minYear);
	}

/*	if(braintree){
		console.log("stripe checkout loaded");
		braintreeTimeout = window.setTimeout(BraintreeInit, 300);
	}*/

	/* implementation */

	//TODO: move out to service?
	function review(){
		// save entered information

		// Validate form
		var forgottenFields = [];
		var requiredFields = {};
		requiredFields.fullname = this.info['fullName'];
		requiredFields.email = this.info['email'];
		requiredFields.address1 = this.info['billingAddress1'];
		requiredFields.phone = this.info['phone'];
		requiredFields.zipcode = this.info['billingZipcode'];
		requiredFields.state = this.info['billingState'];
		requiredFields.city = this.info['billingCity'];
		requiredFields.country = this.info['billingCountry'];
		//TODO: probably need to remove these fields
		if(!this.isLoggedIn()){
			requiredFields.creditCardNumber = this.info['creditCardNumber'];
			requiredFields.expMonth = this.info['expMonth'];
			requiredFields.expYear = this.info['expYear'];
			requiredFields.cvv = this.info['cvv'];
		}
		if(this.info['shippingSameAsBilling']){
			// Copy shipping address to billing address
			this.info['shippingAddress1'] = this.info['billingAddress1'];
			this.info['shippingAddress2'] = this.info['billingAddress2'];
			this.info['shippingZipcode'] = this.info['billingZipcode'];
			this.info['shippingCity'] = this.info['billingCity'];
			this.info['shippingState'] = this.info['billingState'];
			this.info['shippingCountry'] = this.info['billingCountry'];
		}

		requiredFields.shippingAddress1 = this.info['shippingAddress1'];
		requiredFields.shippingZipcode = this.info['shippingZipcode'];
		requiredFields.shippingCity = this.info['shippingCity'];
		requiredFields.shippingState = this.info['shippingState'];
		requiredFields.shippingCountry = this.info['shippingCountry'];

		// Add any empty fields to forgotten field list
		for(var key in requiredFields){
			if(requiredFields[key] == "" || requiredFields[key] == null){
				forgottenFields.push(key);
			}
		}
		// Alert user if they forgot to enter anything
		// Otherwise censor credit card info and go to review page
		checkoutService.setInfo(this.info);
		if(forgottenFields.length == 0){
			// Save user info to local storage
			localStorageService.add('info', this.info);
			//authorize card
			if(this.isLoggedIn()){
				simplifyService.doRegisteredAuthorize(this.info, function(data){
					var info = checkoutService.getInfo();
					info['cvv'] = "***";
					info['creditCardNumber'] = "****************";
					info['authorizationId'] = data.authorizationId;

					localStorageService.add('info', info);
					checkoutService.setInfo(info);
					$state.go("review");
				}, function(data){
					alert('Failed to authorize card');
				});
			}else{
				simplifyService.doAuthorize(this.info, function(data){
					//TODO: save authorization id
					var info = checkoutService.getInfo();
					info['cvv'] = "***";
					info['creditCardNumber'] = "************" + info['creditCardNumber'].slice(-4);
					info['authorizationId'] = data.authorizationId;

					localStorageService.add('info', info);
					checkoutService.setInfo(info);
					$state.go("review");
				}, function(data){
					alert('Failed to authorize card');
				});
			}

		}
	};


	function masterpass(){
		checkoutService.startMasterpassCheckout();
	}

	function detectCardType(number){
		// maestro
		if (/^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/.test(number)){
			return "maestro";
		}

		// mastercard
		if (/^5[1-5][0-9]{5,}$/.test(number)){
			return "master";
		}

		// visa
		if (/^4[0-9]{6,}$/.test(number)){
			return "visa";
		}

		// amex
		if (/^3[47][0-9]{5,}$/.test(number)){
			return "amex";
		}

		// diners
		if (/^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/.test(number)){
			return "diners";
		}

		// discover
		if (/^6(?:011|5[0-9]{2})[0-9]{3,}$/.test(number)){
			return "discover";
		}

		// unknown
		return null;
	}

	function BraintreeInit(){
		//TODO: call braintree service to get client token
		braintreeService.getClientToken(function(data){
			braintree.setup(data.clientToken, "custom", {
				id: 'inputForm',
				hostedFields: {
					number: {
						selector: '#card-number'
					},
					cvv: {
						selector: '#cvv'
					},
					expirationDate: {
						selector : '#expiration-date',
						placeholder: 'MM/YYYY'
					},
					styles: {
						"input": {
							"font-size": "18px"
						}
					}
				},
				onPaymentMethodReceived: function (obj){
					//do something here
					console.log(obj);
					reviewBraintree(obj, self);
				}
			})
		})
	}

	function reviewBraintree(obj, self){
		// save entered information

		// Validate form
		var forgottenFields = [];
		var requiredFields = {};
		requiredFields.fullname = self.info['fullName'];
		requiredFields.email = self.info['email'];
		requiredFields.address1 = self.info['billingAddress1'];
		requiredFields.phone = self.info['phone'];
		requiredFields.zipcode = self.info['billingZipcode'];
		requiredFields.state = self.info['billingState'];
		requiredFields.city = self.info['billingCity'];
		requiredFields.country = self.info['billingCountry'];
		if(self.info['shippingSameAsBilling']){
			// Copy shipping address to billing address
			self.info['shippingAddress1'] = self.info['billingAddress1'];
			self.info['shippingAddress2'] = self.info['billingAddress2'];
			self.info['shippingZipcode'] = self.info['billingZipcode'];
			self.info['shippingCity'] = self.info['billingCity'];
			self.info['shippingState'] = self.info['billingState'];
			self.info['shippingCountry'] = self.info['billingCountry'];
		}

		requiredFields.shippingAddress1 = self.info['shippingAddress1'];
		requiredFields.shippingZipcode = self.info['shippingZipcode'];
		requiredFields.shippingCity = self.info['shippingCity'];
		requiredFields.shippingState = self.info['shippingState'];
		requiredFields.shippingCountry = self.info['shippingCountry'];

		// Add any empty fields to forgotten field list
		for(var key in requiredFields){
			if(requiredFields[key] == "" || requiredFields[key] == null){
				forgottenFields.push(key);
			}
		}
		// Alert user if they forgot to enter anything
		// braintree only provides last two digits and card type, no other data
		if(forgottenFields.length == 0){
			self.info['cvv'] = "***";
			self.info['creditCardNumber'] = "**************" + obj.details.lastTwo;
			self.info['expMonth'] = "**";
			self.info['expYear'] = "****";
			self.info['nonce'] = obj.nonce;
			// Save user info to local storage
			localStorageService.add('info', self.info);
			$state.go("review");
		}

		checkoutService.setInfo(self.info);
	}
	$scope.status = 'ready';
};

module.exports = function (module) {
	var name = 'CheckoutController';
	module.controller(name, controller);

	return name;
};
