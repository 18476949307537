'use strict';

var controller = function ($scope, $window, app, cart, $http, $location, checkoutService, localStorageService, logger, textBundle, userSessionStore, $state) {
	logger('confirm controller');

	/* declaration */

	$scope.app = app;
	app.currentPage = $state.current.name;

	console.log(checkoutService.getInfo());

	$scope.info = checkoutService.getInfo();

	$scope.cart = cart.cartContainer;

	console.log($scope.cart);

	$scope.digitalOrder = $scope.info.digitalOrder;

	$window.scrollTo(0,0);

	$scope.visaCheckoutFlow = checkoutService.isVisaCheckoutFlow();

	$scope.paypalExpressCheckoutFlow = checkoutService.isPayPalExpressCheckoutFlow();

	$scope.braintreeCheckoutFlow = checkoutService.isBraintreeExpressCheckoutFlow();

	$scope.braintreePayPalCheckoutFlow = checkoutService.isBraintreePayPal();

	/* initialization */

	// Clear entered user info
	checkoutService.setInfo(null);
	cart.clearCart();

	// Redirect to cart if order confirmation has not been set to true
	if(!cart.orderConfirmation){
		$state.go('cart');
	}
	else {
		cart.orderConfirmation = false;
	}

	// Retrieve latest addition to Order History
	var lastKey = Object.keys(localStorageService.get("OrderHistory")).sort().reverse()[0];
	$scope.currentOrder = localStorageService.get("OrderHistory")[lastKey];



	/* implementation */
	$scope.status = 'ready';
};

module.exports = function (module) {
	var name = 'ConfirmController';
	module.controller(name, controller);

	return name;
};