'use strict';
var angular = require('angular');
var simplifyService = angular.module('simplifyService', []);

var factory = function (app, localStorageService, $http, cart) {
	return {
		doAuthorize: function(info, successCallback, failureCallback){
			var self = this;
			var requestUrl = "https://www.progamehub.com/mtt/simplify/authorizePayment";
			app.loading=true;
			var requestParameters = {};
			requestParameters.amount = cart.cartContainer.totalPrice.toFixed(2);
			requestParameters.card = {
				cardholderName: info['fullName'],
				cardNumber: info['creditCardNumber'],
				cvc: info['cvv'],
				expMonth: info['expMonth'],
				expYear: info['expYear']
			};
			requestParameters.address = {
				line1: info['billingAddress1'],
				line2: info['billingAddress2'],
				city: info['billingCity'],
				state: info['billingState'],
				country: info['billingCountry'],
				postalCode: info['billingZipcode']
			};

			$http.post(requestUrl, requestParameters).then(
				function(successResponse){
					// transaction successful
					app.loading = false;
					if(successResponse.data.paymentStatus != 'APPROVED'){
						failureCallback(successResponse.data)
					}else{
						successCallback(successResponse.data);
					}
				},
				function(failureResponse){
					app.loading = false;
					console.log(failureResponse)
				}
			);
		},
		doRegisteredAuthorize: function(info, successCallback, failureCallback){
			var self = this;
			var requestUrl = "https://www.progamehub.com/mtt/simplify/authorizeRegisteredPayment";
			app.loading=true;
			var requestParameters = {};
			requestParameters.amount = cart.cartContainer.totalPrice.toFixed(2);
			requestParameters.address = {
				line1: info['billingAddress1'],
				line2: info['billingAddress2'],
				city: info['billingCity'],
				state: info['billingState'],
				country: info['billingCountry'],
				postalCode: info['billingZipcode']
			};

			$http.post(requestUrl, requestParameters).then(
				function(successResponse){
					// transaction successful
					app.loading = false;
					if(successResponse.data.paymentStatus != 'APPROVED'){
						failureCallback(successResponse.data)
					}else{
						successCallback(successResponse.data);
					}
				},
				function(failureResponse){
					app.loading = false;
					console.log(failureResponse)
				}
			);
		},
		doPayment : function(authorizationId, successCallback, failureCallback){
			var self = this;
			var requestUrl = "https://www.progamehub.com/mtt/simplify/processPayment";
			app.loading=true;
			var requestParameters = {};

			requestParameters.authorizationId = authorizationId;
			requestParameters.amount = cart.cartContainer.totalPrice.toFixed(2);
			$http.post(requestUrl, requestParameters).then(
				function(successResponse){
					// transaction successful
					app.loading = false;
					if(successResponse.data.paymentStatus != 'APPROVED'){
						failureCallback(successResponse.data)
					}else{
						successCallback(successResponse.data);
					}
				},
				function(failureResponse){
					app.loading = false;
					console.log(failureResponse)
				}
			);
		}
	};
};

simplifyService.factory('simplifyService', ['app', 'localStorageService', '$http', 'cart', factory]);
module.exports = simplifyService.name;