'use strict';

var controller = function ($scope, app, masterpass, categoryService, $http, $location, logger, textBundle, userSessionStore, $state) {
	logger('home controller');
	this.app = app;
	app.currentPage = $state.current.name;
	console.log(app.currentPage);
	this.imageLocation = "images/home/";
	this.links = {
		1: "#/products?catId=PS4%20Consoles",
		2: "#/products?catId=Wii%20U%20Games",
		3: "#/products?catId=PS4%20Games",
		4: "#/product?productId=1454123"
	};

	$scope.status = 'ready';
};

module.exports = function (module) {
	var name = 'HomeController';
	module.controller(name, controller);
	return name;
};