'use strict';
var angular = require('angular');
var stripeService = angular.module('stripeService', []);

var factory = function (app, localStorageService, $http, cart) {
	return {
		createCharge : function(token, orderAmount, successCallback){
			var requestUrl = "https://www.progamehub.com/mtt/Stripe/createCharge"

			var requestBody = token;
			var requestParameters = {};
			requestParameters.orderAmount = orderAmount;
			$http.post(requestUrl, requestBody, { params : requestParameters }).then(
				function(successResponse){
					app.loading = false;
					cart.orderConfirmation = true;
					successCallback(successResponse);
				},
				function(failureResponse){
					app.loading = false;
					console.log(failureResponse)
				}
			);

		}
	};
};

stripeService.factory('stripe', ['app', 'localStorageService', '$http', 'cart', factory]);
module.exports = stripeService.name;