'use strict';
var angular = require('angular');
var checkoutServiceProvider = angular.module('checkoutServiceProvider', []);


var factory = function (app, braintreeService, configurationManager, cart, localStorageService, masterpass, accountService, visa, paypal, stripe, simplifyService, $state) {

	return {
		info : null,

		getInfo : function(){
			this.info = localStorageService.get('checkoutInformation') || { shippingSameAsBilling : true };
			return this.info;
		},
		setInfo : function(info){
			this.info = info;
			localStorageService.add('checkoutInformation', this.info);
		},
		setPrecheckoutTransactionId : function(precheckoutTransactionId){
			var info = this.getInfo();
			info.precheckoutTransactionId = precheckoutTransactionId;
			this.setInfo(info);
		},

		/**
		 * takes user to appropriate checkout page 
		 *   May request precheckout data if long access token is available
		 */
		startCheckout : function(){
			var self = this;

			// remove precheckout transaction id
			self.setPrecheckoutTransactionId(null);
			masterpass.clearCheckoutData();
			$state.go('checkout');
		},



		startMasterpassCheckout : function(){
			var self = this;

			if(configurationManager.state.checkoutType != masterpass.CHECKOUT_TYPE.STANDARD && accountService.isLoggedIn() && configurationManager.state.longAccessToken){
				masterpass.precheckoutData(
					function(data){
						// masterpass return/express checkout
						if(configurationManager.state.preCheckoutData){
							// set precheckout transaction id on cart
							self.setPrecheckoutTransactionId(configurationManager.state.precheckoutTransactionId);
							app.loading = false;
							$state.go('returnCheckout');
						}else{
							// retrieving precheckout data failed. do standard masterpass checkout
							self.startMasterpassCheckout();
						}
					},
					cart.cartContainer
				);
			}else{
				// standard masterpass checkout
				self.setPrecheckoutTransactionId(null);
				masterpass.checkout(
					self.masterpassCheckoutCallback.bind(self),
					self.masterpassCheckoutFailureCallback.bind(self),
					cart.cartContainer,
					!accountService.isLoggedIn()
				);
			}
		},

		startPayPalCheckout : function(){
			var self = this;
			paypal.startExpressCheckout(cart.cartContainer, function(redirectUrl){
				//console.log(redirectUrl);
				window.location = redirectUrl;
			});

		},

		startPayPalRestCheckout : function(){
			var self = this
			paypal.startRestCheckout(cart.cartContainer, function(redirectUrl){
				//console.log(redirectUrl);
				window.location = redirectUrl;
			});
		},

		masterpassV7CheckoutCallback : function(oauth_token,oauth_verifier,checkout_resource_url){
			var self = this;
			console.log("masterpassCheckoutCallback");
			//console.log(cart.cartContainer.cartId);
			app.loading = false;
			masterpass.checkoutV7Callback(
				self.masterpassCheckoutV7Callback.bind(self),
				self.masterpassCheckoutFailureCallback.bind(self),
				oauth_token,
				oauth_verifier,
				checkout_resource_url,
				cart.cartContainer
				);
		},


		masterpassCheckoutV7Callback : function(data){
			console.log("masterpassCheckoutCallback")
			app.loading = false;
			if(data && !data.errorMessage){

				this.applyCheckoutResourceV7(data);

				// go to receipt page
				$state.go("review");
			}else{
				console.log(JSON.stringify(data.errorMessage));
				this.masterpassCheckoutFailureCallback(data);
			}
		},

		masterpassCheckoutCallback : function(data){
			console.log("masterpassCheckoutCallback")
			app.loading = false;
			if(data && !data.errorMessage){

				this.applyCheckoutResource(data.checkout);

				// go to receipt page
				$state.go("review");
			}else{
				console.log(JSON.stringify(data.errorMessage));
				this.masterpassCheckoutFailureCallback(data);
			}
		},

		masterpassCheckoutFailureCallback : function(data){
			app.loading = false;
			//error with response.  alert user
			alert(configurationManager.state.genericErrorMessage); //TODO: proper handling for error
			$state.go("cart");
		},


		isMasterpassReturnCheckout : function(){
			if(this.info.precheckoutTransactionId && configurationManager.state.checkoutType == masterpass.CHECKOUT_TYPE.CONNECT){
				return true;
			}else{
				return false;
			}
		},

		isMasterpassExpressCheckout : function(){
			if(this.info.precheckoutTransactionId && configurationManager.state.checkoutType == masterpass.CHECKOUT_TYPE.EXPRESS){
				return true;
			}else{
				return false;
			}
		},

		initalizeReview : function(){
			// if applicable, create return or express checkout buttons
			var self = this;

			// initaliaze for return or express checkout
			if(self.info.precheckoutTransactionId){
				// apply selected precheckout data

				self.applyPrecheckoutData();

				// return checkout
				if(self.isMasterpassReturnCheckout()){
					app.loading = true;
					masterpass.returnCheckout(
						'mp_button',
						self.masterpassReturnCheckout.bind(self),
						self.masterpassReturnCheckoutFailure.bind(self),
						cart.cartContainer
					);
				}
			}
		},

		masterpassReturnCheckout : function(data){
			console.log("Return Checkout data: %o", data);
			app.loading = false;

			// place details into cehckout
			this.applyCheckoutResource(data.checkout);

			this.placeOrder();
		},

		masterpassReturnCheckoutFailure : function(data){
			console.log("Return Checkout data: %o", data);
			console.log(cart.cartContainer.cartId)
			app.loading = false;
			// clear masterpass checkout status
			this.setPrecheckoutTransactionId(null);

			// alert user
			alert('Error with placing order. Exiting MasterPass checkout flow.'); // TODO: need to pass error message

			//Go to checkout
			$state.go('checkout');
		},

		placeOrder : function(){
			var self = this;
			if(!this.info){
				this.getInfo();
			}
			if(self.isMasterpassExpressCheckout()){
				app.loading = true;
				// express checkout
				masterpass.expressCheckout(
					self.masterpass3dsCallback.bind(self),
					self.masterpass3dsFailureCallback.bind(self),
					cart.cartContainer
				);
			}else if(this.info['authorizationId']){
				simplifyService.doPayment(this.info['authorizationId'], function(data){
					self._placeOrder2();
				}, function(data){
					alert('Failed to charge card');
				});
			}else{
				self._placeOrder();
			}

		},
		_placeOrder : function(){
			// Confirm order placement, generate order id
			cart.orderConfirmation = true;

			if(cart.cartContainer.digital){
				this.info.digitalOrder = true;
			}else{
				this.info.digitalOrder = false;
			}
			this.setInfo(this.info);

			// Add to order history in local storage
			var orderHistory = localStorageService.get('OrderHistory');

			if(orderHistory != null){
				console.log(orderHistory);
				var newOrderId = orderHistory[orderHistory.length-1] + 1;
				orderHistory.push(newOrderId);
				localStorageService.add('OrderHistory', orderHistory);
			}
			else {
				var nextId = Math.floor(Math.random() * (200000 - 100000 + 1)) + 100000;
				var idHolder = [];
				idHolder.push(nextId);
				localStorageService.add('OrderHistory', idHolder);
			}

			masterpass.postback(cart.cartContainer);

			// clear masterpass checkout data
			masterpass.clearCheckoutData();

			// go to receipt page
			$state.go("confirm");
		},
		_placeOrder2 : function(){
			// Confirm order placement, generate order id
			cart.orderConfirmation = true;

			if(cart.cartContainer.digital){
				this.info.digitalOrder = true;
			}else{
				this.info.digitalOrder = false;
			}
			this.setInfo(this.info);

			// Add to order history in local storage
			var orderHistory = localStorageService.get('OrderHistory');

			if(orderHistory != null){
				console.log(orderHistory);
				var newOrderId = orderHistory[orderHistory.length-1] + 1;
				orderHistory.push(newOrderId);
				localStorageService.add('OrderHistory', orderHistory);
			}
			else {
				var nextId = Math.floor(Math.random() * (200000 - 100000 + 1)) + 100000;
				var idHolder = [];
				idHolder.push(nextId);
				localStorageService.add('OrderHistory', idHolder);
			}

			// go to receipt page
			$state.go("confirm");
		},
		masterpass3dsCallback : function(data){
			app.loading = false;
			if(data){
				console.log("Express Checkout data: %o", data);

				this.applyCheckoutResource(data.expressCheckoutResponseData.checkout);

				this._placeOrder();
			}
		},

		masterpass3dsFailureCallback : function(data){
			app.loading = false;
			console.log("Express Checkout data: %o", data);

			// clear masterpass checkout status
			this.setPrecheckoutTransactionId(null);

			// alert user
			alert('Error with placing order. Exiting MasterPass checkout flow.'); // TODO: need to pass error message

			//Go to review
			$state.go('checkout');

		},

		setMasterpassBranding : function(usedData, walletPartnerLogoUrl, masterpassLogoUrl){
			if(!this.info){
				this.getInfo();
			}
			if(usedData.shipping){
				this.info.shippingMasterpassBrandingLogo = masterpassLogoUrl;
				this.info.shippingMasterpassBrandingPartnerLogo = walletPartnerLogoUrl;
			}
			if(usedData.billing){
				this.info.billingMasterpassBrandingLogo = masterpassLogoUrl;
				this.info.billingMasterpassBrandingPartnerLogo = walletPartnerLogoUrl;
			}
			if(usedData.rewards){
				this.info.rewardMasterpassBrandingLogo = masterpassLogoUrl;
				this.info.rewardMasterpassBrandingPartnerLogo = walletPartnerLogoUrl;
			}
			this.setInfo(this.info);
		},

		applyPrecheckoutData : function(){
			var precheckoutData = configurationManager.state.preCheckoutData;
			var cardId = configurationManager.state.precheckoutCardId;
			var shippingId = configurationManager.state.precheckoutShippingId;
			var loyaltyId = configurationManager.state.precheckoutLoyaltyId;

			console.log(precheckoutData);

			var checkout = {
					contact : precheckoutData.contact,
					shippingAddress : null,
					card : null,
					rewardProgram : null
			};

			if(cardId){
				for(var i = 0; i < precheckoutData.cards.cards.length; i++){
					if(precheckoutData.cards.cards[i].cardId == cardId){
						checkout.card = precheckoutData.cards.cards[i];
						break;
					}
				}
			}

			if(shippingId){
				for(var i = 0; i < precheckoutData.shippingAddresses.shippingAddresses.length; i++){
					if(precheckoutData.shippingAddresses.shippingAddresses[i].addressId == shippingId){
						checkout.shippingAddress = precheckoutData.shippingAddresses.shippingAddresses[i];
						break;
					}
				}
			}

			if(loyaltyId){
				for(var i = 0; i < precheckoutData.rewardPrograms.rewardPrograms.length; i++){
					if(precheckoutData.rewardPrograms.rewardPrograms[i].rewardId == loyaltyId){
						checkout.rewardProgram = precheckoutData.rewardPrograms.rewardPrograms[i];
						break;
					}
				}
			}

			this.applyCheckoutResource(checkout);
		},


		applyCheckoutResourceV7 : function(checkout){
			if(!this.info){
				this.getInfo();
			}
			console.log(checkout.personalInfo);
			this.info['shippingSameAsBilling'] = false;

			/* contact */
			if(checkout.personalInfo){
				this.info['fullName'] = checkout.personalInfo.recipientName;
				this.info['phone'] = checkout.personalInfo.recipientPhone;
			}

			/* shipping */
			if(checkout.shippingAddress){
				// place cart data into place
				// checkout.shippingAddress.recipientName
				this.info['shippingAddress1'] = checkout.shippingAddress.line1
				this.info['shippingAddress2'] = checkout.shippingAddress.line2
				// checkout.shippingAddress.line3
				// checkout.shippingAddress.city
				// checkout.shippingAddress.recipientPhoneNumber
				this.info['shippingZipcode'] = checkout.shippingAddress.postalCode
				this.info['shippingCountry'] = checkout.shippingAddress.country
				var arr = checkout.shippingAddress.subdivision.split('-');
				this.info['shippingState'] = arr[arr.length-1];
				this.info['shippingCity'] = checkout.shippingAddress.city

			}

			/* billing */
			if(checkout.card && checkout.card.billingAddress){
				this.info['fullName'] = checkout.card.cardHolderName
				this.info['billingAddress1'] = checkout.card.billingAddress.line1
				this.info['billingAddress2'] = checkout.card.billingAddress.line2
				// checkout.card.billingAddress.line3
				this.info['billingCity'] = checkout.card.billingAddress.city
				this.info['billingZipcode'] = checkout.card.billingAddress.postalCode
				this.info['billingCountry'] = checkout.card.billingAddress.country
				var arr = checkout.card.billingAddress.subdivision.split('-');
				this.info['billingState'] = arr[arr.length-1];
			}

			/* payment */
			if(checkout.card){
				if(checkout.card.accountNumber){
					this.info['creditCardNumber'] = checkout.card.accountNumber
				}
				this.info['expMonth'] = checkout.card.expiryMonth
				this.info['expYear'] = checkout.card.expiryYear
				// checkout.card.cardHolderName
				this.info['cardTypeName'] = checkout.card.brandName
				this.info['cardType'] = checkout.card.brandId
				// checkout.card.extensionPoint
			}

			/* rewards */
			if(checkout.rewardProgram){
				// checkout.rewardProgram.rewardId
				// checkout.rewardProgram.rewardName
				this.info['rewardProgram'] = checkout.rewardProgram.rewardNumber
				// checkout.rewardProgram.expiryMonth
				// checkout.rewardProgram.expiryYear
				// checkout.rewardProgram.extensionPoint
			}

			/* apply masks */
			if(this.info['creditCardNumber']){
				this.info['creditCardNumber'] = "************" + this.info['creditCardNumber'].slice(-4);
			}

			/* save */
			localStorageService.add('checkoutInformation', this.info);
		},


		applyCheckoutResource : function(checkout){
			if(!this.info){
				this.getInfo();
			}

			this.info['shippingSameAsBilling'] = false;

			/* contact */
			if(checkout.contact){
				this.info['email'] = checkout.contact.emailAddress;
				// checkout.contact.country
				// checkout.contact.dateOfBirth
				// checkout.contact.extensionPoint
				this.info['fullName'] = checkout.contact.firstName;
				this.info['fullName'] += ' ' + checkout.contact.lastName;
				// checkout.contact.middleName
				// checkout.contact.nationalID
				this.info['phone'] = checkout.contact.phoneNumber;
			}

			/* shipping */
			if(checkout.shippingAddress){
				// place cart data into place
				// checkout.shippingAddress.recipientName
				this.info['shippingAddress1'] = checkout.shippingAddress.line1
				this.info['shippingAddress2'] = checkout.shippingAddress.line2
				// checkout.shippingAddress.line3
				// checkout.shippingAddress.city
				// checkout.shippingAddress.recipientPhoneNumber
				this.info['shippingZipcode'] = checkout.shippingAddress.postalCode
				this.info['shippingCountry'] = checkout.shippingAddress.country
				var arr = checkout.shippingAddress.countrySubdivision.split('-');
				this.info['shippingState'] = arr[arr.length-1];
				// checkout.shippingAddress.extensionPoint
			}

			/* billing */
			if(checkout.card && checkout.card.billingAddress){
				this.info['fullName'] = checkout.card.cardHolderName
				this.info['billingAddress1'] = checkout.card.billingAddress.line1
				this.info['billingAddress2'] = checkout.card.billingAddress.line2
				// checkout.card.billingAddress.line3
				this.info['billingZipcode'] = checkout.card.billingAddress.postalCode
				// checkout.card.billingAddress.city
				this.info['billingCountry'] = checkout.card.billingAddress.country
				var arr = checkout.card.billingAddress.countrySubdivision.split('-');
				this.info['billingState'] = arr[arr.length-1];
			}

			/* payment */
			if(checkout.card){
				this.info['creditCardNumber'] = checkout.card.lastFour
				if(checkout.card.accountNumber){
					this.info['creditCardNumber'] = checkout.card.accountNumber
				}
				this.info['expMonth'] = checkout.card.expiryMonth
				this.info['expYear'] = checkout.card.expiryYear
				// checkout.card.cardHolderName
				this.info['cardTypeName'] = checkout.card.brandName
				this.info['cardType'] = checkout.card.brandId
				// checkout.card.extensionPoint
			}

			/* rewards */
			if(checkout.rewardProgram){
				// checkout.rewardProgram.rewardId
				// checkout.rewardProgram.rewardName
				this.info['rewardProgram'] = checkout.rewardProgram.rewardNumber
				// checkout.rewardProgram.expiryMonth
				// checkout.rewardProgram.expiryYear
				// checkout.rewardProgram.extensionPoint
			}

			/* apply masks */
			if(this.info['creditCardNumber']){
				this.info['creditCardNumber'] = "************" + this.info['creditCardNumber'].slice(-4);
			}

			/* save */
			localStorageService.add('checkoutInformation', this.info);
		},

		applyVisaCheckoutResource : function(data, callid){
			if(!this.info){
				this.getInfo();
			}

			this.info['callid'] = callid;

			this.info['isVisaCheckout'] = true;

			this.info['shippingSameAsBilling'] = false;

			/* contact */
			if(data.userInfo){
				this.info['email'] = data.userInfo.userEmail;
				// checkout.contact.country
				// checkout.contact.dateOfBirth
				// checkout.contact.extensionPoint
				this.info['fullName'] = data.userInfo.userFullName;
				// checkout.contact.middleName
				// checkout.contact.nationalID
			}

			/* shipping */
			if(data.shippingAddress){
				// place cart data into place
				// checkout.shippingAddress.recipientName
				this.info['shippingAddress1'] = data.shippingAddress.line1
				this.info['shippingAddress2'] = data.shippingAddress.line2
				// checkout.shippingAddress.line3
				// checkout.shippingAddress.city
				this.info['shippingCity'] = data.shippingAddress.city
				// checkout.shippingAddress.recipientPhoneNumber
				this.info['shippingZipcode'] = data.shippingAddress.postalCode
				this.info['shippingCountry'] = data.shippingAddress.countryCode
				this.info['shippingState'] = data.shippingAddress.stateProvinceCode;
				// checkout.shippingAddress.extensionPoint
			}

			/* billing */
			if(data.paymentInstrument && data.paymentInstrument.billingAddress){
				this.info['fullName'] = data.paymentInstrument.nameOnCard
				this.info['billingAddress1'] = data.paymentInstrument.billingAddress.line1
				this.info['billingAddress2'] = data.paymentInstrument.billingAddress.line2
				// checkout.card.billingAddress.line3
				this.info['billingZipcode'] = data.paymentInstrument.billingAddress.postalCode
				// checkout.card.billingAddress.city
				this.info['billingCity'] = data.paymentInstrument.billingAddress.city
				this.info['billingCountry'] = data.paymentInstrument.billingAddress.countryCode
				this.info['billingState'] = data.paymentInstrument.billingAddress.stateProvinceCode;
				this.info['phone'] = data.paymentInstrument.billingAddress.phone;
			}

			/* payment */
			if(data.paymentInstrument){
				this.info['creditCardNumber'] = data.paymentInstrument.lastFourDigits
				this.info['expMonth'] = data.paymentInstrument.expirationDate.month
				this.info['expYear'] = data.paymentInstrument.expirationDate.year
				// checkout.card.cardHolderName
				// checkout.card.brandName
				// checkout.card.brandId
				// checkout.card.extensionPoint
			}

			/* apply masks */
			if(this.info['creditCardNumber']){
				this.info['creditCardNumber'] = "************" + this.info['creditCardNumber'].slice(-4);
			}

			/* save */
			localStorageService.add('checkoutInformation', this.info);
		},

		isVisaCheckoutFlow : function(){
			if(this.info.isVisaCheckout){
				return true;
			}else{
				return false;
			}
		},

		placeMasterPassV7CheckoutOrder : function(){
			var self = this;
			if(!this.info){
				this.getInfo();
			}
			localStorageService.add('checkoutInformation', self.info);
			self._placeOrder();
		},


		placeVisaCheckoutOrder : function(){
			var self = this;
			if(!this.info){
				this.getInfo();
			}
			//do visa checkout specific operations here
			//generate update payment info pixel
			visa.generatePixel(cart, this.info.callid, function(url){
				self.info['visaPixel'] = url;
				console.log(self.info);
				localStorageService.add('checkoutInformation', self.info);
				self._placeOrder2();
			});
			/* save */
		},
		placePayPalExpressCheckoutOrder : function(){
			var self = this;
			if(!this.info){
				this.getInfo();
			}

			paypal.doExpressCheckoutPayment(this.info.paypalToken, this.info.paypalPayerId, cart.cartContainer, function(data){
				console.log(self.info);
				localStorageService.add('checkoutInformation', self.info);
				self._placeOrder2();
			});
		},
		applyCheckoutPayPalResource : function(data){
			if(!this.info){
				this.getInfo();
			}

			this.info['paypalToken'] = data.token;
			this.info['paypalPayerId'] = data.payerInfo.payerID;

			this.info['shippingSameAsBilling'] = false;

			/* contact */
			if(data.payerInfo){
				this.info['email'] = data.payerInfo.payer;
				// checkout.contact.country
				// checkout.contact.dateOfBirth
				// checkout.contact.extensionPoint
				this.info['fullName'] = data.payerInfo.payerName.firstName + ' ' + data.payerInfo.payerName.lastName;
				// checkout.contact.middleName
				// checkout.contact.nationalID
			}

			/* shipping */
			if(data.payerInfo.address){
				// place cart data into place
				// checkout.shippingAddress.recipientName
				this.info['shippingAddress1'] = data.payerInfo.address.street1
				this.info['shippingAddress2'] = data.payerInfo.address.street2
				// checkout.shippingAddress.line3
				this.info['shippingCity'] = data.payerInfo.address.cityName
				// checkout.shippingAddress.recipientPhoneNumber
				this.info['shippingZipcode'] = data.payerInfo.address.postalCode
				this.info['shippingCountry'] = data.payerInfo.address.country
				this.info['shippingState'] = data.payerInfo.address.stateOrProvince;
				// checkout.shippingAddress.extensionPoint
			}

			/* save */
			localStorageService.add('checkoutInformation', this.info);
		},
		applyCheckoutPayPalRestResource : function(data){
			if(!this.info){
				this.getInfo();
			}

			this.info['paypalToken'] = data.id;
			this.info['paypalPayerId'] = data.payer.payerInfo.payerId;

			this.info['shippingSameAsBilling'] = false;

			/* contact */
			if(data.payer.payerInfo){
				this.info['email'] = data.payer.payerInfo.email;
				// checkout.contact.country
				// checkout.contact.dateOfBirth
				// checkout.contact.extensionPoint
				this.info['fullName'] = data.payer.payerInfo.firstName + ' ' + data.payer.payerInfo.lastName;
				// checkout.contact.middleName
				// checkout.contact.nationalID
			}

			/* shipping */
			if(data.payer.payerInfo.shippingAddress){
				// place cart data into place
				// checkout.shippingAddress.recipientName
				this.info['shippingAddress1'] = data.payer.payerInfo.shippingAddress.line1
				this.info['shippingAddress2'] = data.payer.payerInfo.shippingAddress.line2
				// checkout.shippingAddress.line3
				this.info['shippingCity'] = data.payer.payerInfo.shippingAddress.city
				// checkout.shippingAddress.recipientPhoneNumber
				this.info['shippingZipcode'] = data.payer.payerInfo.shippingAddress.postalCode
				this.info['shippingCountry'] = data.payer.payerInfo.shippingAddress.countryCode
				this.info['shippingState'] = data.payer.payerInfo.shippingAddress.state;
				// checkout.shippingAddress.extensionPoint
			}

			/* save */
			localStorageService.add('checkoutInformation', this.info);
			this._placeOrder();
		},
		isPayPalExpressCheckoutFlow : function(){
			if(this.info.paypalToken){
				return true;
			}else{
				return false;
			}
		},

		applyStripeCheckoutResource : function(args, token){
			if(!this.info){
				this.getInfo();
			}

			this.info['shippingSameAsBilling'] = false;

			this.info['stripeCheckout'] = true;

			/* contact */
			this.info['email'] = token.email;
			// checkout.contact.country
			// checkout.contact.dateOfBirth
			// checkout.contact.extensionPoint
			this.info['fullName'] = args.shipping_name;
			// checkout.contact.middleName
			// checkout.contact.nationalID
			this.info['phone'] = '';
			
			/* shipping */
			// place cart data into place
			// checkout.shippingAddress.recipientName
			this.info['shippingAddress1'] = args.shipping_address_line1
			this.info['shippingAddress2'] = ''
			// checkout.shippingAddress.line3
			this.info['shippingCity'] = args.shipping_address_city
			// checkout.shippingAddress.recipientPhoneNumber
			this.info['shippingZipcode'] = args.shipping_address_zip
			this.info['shippingCountry'] = args.shipping_address_country_code
			this.info['shippingState'] = args.shipping_address_state;
			// checkout.shippingAddress.extensionPoint

			/* billing */
			this.info['fullName'] = args.billing_name
			this.info['billingAddress1'] = args.shipping_address_line1
			this.info['billingAddress2'] = ''
			// checkout.card.billingAddress.line3
			this.info['billingZipcode'] = args.billing_address_zip
			this.info['billingCity'] = args.billing_address_city
			this.info['billingCountry'] = args.shipping_address_country_code
			this.info['billingState'] = args.shipping_address_state;

			if(token.card){
				this.info['creditCardNumber'] = token.card.last4
				this.info['expMonth'] = token.card.exp_month
				this.info['expYear'] = token.card.exp_year
			}

			/* apply masks */
			if(this.info['creditCardNumber']){
				this.info['creditCardNumber'] = "************" + this.info['creditCardNumber'].slice(-4);
			}

			/* save */
			localStorageService.add('checkoutInformation', this.info);
		},
		isStripeCheckoutFlow : function(){
			if(this.info.stripeCheckout){
				return true;
			}else{
				return false;
			}
		},
		createStripeCharge : function(token, orderAmount, successCallback){
			stripe.createCharge(token, orderAmount, successCallback);
		},
		handleBraintreeNonce : function(obj, successCallback){
			if(!this.info){
				this.getInfo();
			}
			if(obj.type == 'PayPalAccount'){
				//is a paypal order
				this.info['nonce'] = obj.nonce;
				this.info['paypalPayerId'] = obj.details.payerId;

				this.info['phone'] = obj.details.phone;
				this.info['shippingSameAsBilling'] = false;
				this.info['email'] = obj.details.email;
				this.info['fullName'] = obj.details.firstName + ' ' + obj.details.lastName;

				this.info['shippingAddress1'] = obj.details.shippingAddress.streetAddress
				this.info['shippingAddress2'] = obj.details.shippingAddress.extendedAddress
				this.info['shippingCity'] = obj.details.shippingAddress.locality
				this.info['shippingZipcode'] = obj.details.shippingAddress.postalCode
				this.info['shippingCountry'] = obj.details.shippingAddress.countryCodeAlpha2
				this.info['shippingState'] = obj.details.shippingAddress.region;

				this.info['billingAddress1'] = obj.details.shippingAddress.streetAddress
				this.info['billingAddress2'] = obj.details.shippingAddress.extendedAddress
				this.info['billingCity'] = obj.details.shippingAddress.locality
				this.info['billingZipcode'] = obj.details.shippingAddress.postalCode
				this.info['billingCountry'] = obj.details.shippingAddress.countryCodeAlpha2
				this.info['billingState'] = obj.details.shippingAddress.region;

			}else{
				//is not a paypal order
				//TODO: implement cc processing
			}

			/* save */
			localStorageService.add('checkoutInformation', this.info);
			successCallback();
		},
		isBraintreeExpressCheckoutFlow : function(){
			if(this.info.nonce){
				return true;
			}else{
				return false;
			}
		}, 
		isBraintreePayPal : function(){
			if(this.info.nonce && this.info.paypalPayerId){
				return true;
			}else{
				return false;
			}
		},
		placeBraintreePayPalOrder : function(){
			var self = this;
			if(!this.info){
				this.getInfo();
			}

			braintreeService.doPayment(this.info.nonce, cart.cartContainer, function(data){
				localStorageService.add('checkoutInformation', self.info);
				self._placeOrder();
			}, function(data){
				alert(data.message);
			});
		},
		placeBraintreeOrder : function(){
			var self = this;
			if(!this.info){
				this.getInfo();
			}

			braintreeService.doPayment(this.info.nonce, cart.cartContainer, function(data){
				localStorageService.add('checkoutInformation', self.info);
				self._placeOrder();
			}, function(data){
				alert(data.message);
			});
		}
	}
};

checkoutServiceProvider.factory('checkoutService', ['app', 'braintreeService', 'configurationManager', 'cart', 'localStorageService', 'masterpass', 'accountService', 'visa', 'paypal', 'stripe', 'simplifyService', '$state', factory]);
module.exports = checkoutServiceProvider.name;
