'use strict';

var controller = function ($scope, $sce, app, ProductData, categoryService, $window, $http, $location, cart, localStorageService, logger, textBundle, userSessionStore, $state, product) {
	logger('product controller');
	$scope.app = app;
	$scope.state = $state;

	$scope.getAccessories = function(accessories){
		console.log(accessories);
		var accessoriesList = [];
		var arrayLength = accessories.length;
		for (var i = 0; i < arrayLength; i++) {
			var accessoryData = ProductData.get({'productId': accessories[i]});
			console.log(accessoryData);
			accessoriesList.push(accessoryData);
		}
		console.log(accessoriesList);
		return accessoriesList;
	}
		
	$scope.text = 1;

	$window.scrollTo(0,0);

	$scope.product = product;
	if(categoryService.categoryPath == null){
		categoryService.categoryPath = product.catId;
	}
	else if(categoryService.categoryPath.length == 0){
		categoryService.categoryPath = product.catId;
	}
	$scope.description = $sce.trustAsHtml($scope.product.description);
	$scope.name = $sce.trustAsHtml($scope.product.name);
	app.currentPage = $state.current.name;

	$scope.currentSrc = product.image;

	$scope.swapSrc = function(newSrc, index){
		$scope.product.altImages.splice($scope.product.altImages.indexOf(newSrc), 1);
		$scope.product.altImages.splice(index, 0, $scope.currentSrc);
		$scope.currentSrc = newSrc;
	}

	$scope.addToCart = function() {
		// Use service function to add item to cart
		// Quantity is retrieved from double bound $scope.text variable
		if (parseInt($scope.text) >= 0) {
			cart.updateCart($scope.product, $scope.text, $scope.product.productId);
			$scope.text = 1;
		}
		else if(parseInt($scope.text) < 0) {
			$scope.text = parseInt($scope.text) * -1;
		}
		else {
			alert("Please enter a number!");
		}
	};
	$scope.status = 'ready';
};

module.exports = function (module) {
	var name = 'ProductController';
	module.controller(name, controller);

	return name;
};