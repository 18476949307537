'use strict';

var controller = function ($scope, app, masterpassDataService, cart, checkoutService, logger, textBundle, $state, configurationManager) {
	logger('returnCheckout controller');

	/* declaration */

	$scope.app = app;
	app.currentPage = $state.current.name;

	$scope.modes = [
			"billing",
			"shipping",
			"reward"
		];
	$scope.currentStep;
	$scope.nextStep;

	$scope.precheckoutData = configurationManager.state.preCheckoutData;

	$scope.walletPartnerLogoUrl = configurationManager.state.walletPartnerLogoUrl;

	$scope.masterpassLogoUrl = configurationManager.state.masterpassLogoUrl;

	$scope.model = {
			shipping : null,
			billing : null,
			reward : null
		}

	$scope.goToNextStep = goToNextStep;



	/* initialization */

	$scope.goToNextStep();



	/* implementation */

	/**
	 * Sets currentStep and nextStep or continues to review page when all steps are completed
	 */
	function goToNextStep(){
		$scope.nextStep = 'Review';
		window.scrollTo(0,0);
		var stepFound = false;
		if($scope.precheckoutData.cards && !$scope.model.billing){
			if(!stepFound){
				$scope.currentStep = $scope.modes[0];
			}else{
				$scope.nextStep = $scope.modes[0];
				return;
			}
			stepFound = true;
		}
		if($scope.precheckoutData.shippingAddresses && !$scope.model.shipping){
			if(!stepFound){
				$scope.currentStep = $scope.modes[1];
			}else{
				$scope.nextStep = $scope.modes[1];
				return;
			}
			stepFound = true;
		}
		if($scope.precheckoutData.rewardPrograms && !$scope.model.reward){
			if(!stepFound){
				$scope.currentStep = $scope.modes[2];
			}else{
				$scope.nextStep = $scope.modes[2];
				return;
			}
			stepFound = true;
		}

		if(!stepFound){
			masterpassDataService.setMasterpassData({
					precheckoutCardId: $scope.model.billing,
					precheckoutShippingId: $scope.model.shipping,
					precheckoutLoyaltyId: $scope.model.reward
				},
				true);

			checkoutService.setMasterpassBranding($scope.model, configurationManager.state.walletPartnerLogoUrl, configurationManager.state.masterpassLogoUrl);
			$state.go('review');
		}
	}
	$scope.status = 'ready';
};

module.exports = function (module) {
	var name = 'ReturnCheckoutController';
	module.controller(name, controller);

	return name;
};