'use strict';
var angular = require('angular');
var uiRouter = require('angular-ui-router');
require('angular-local-storage');
var appService = require('./services/appService');
var configurationManager = require('./services/configurationManager');
var masterpassService = require('./services/masterpassService');
var localListManagerService = require('./services/localListManager');
var paypalService = require('./services/paypalService');
var visaService = require('./services/visaService');
var stripeService = require('./services/stripeService');
var braintreeService = require('./services/braintreeService')
var simplifyService = require('./services/simplifyService')
var accountService = require('./services/accountService');
var cartService = require('./services/cartService');
var quickCartService = require('./services/quickCartService');
var checkoutService = require('./services/checkoutService');
var traceManager = require('./services/traceManager');
var masterpassDataService = require('./services/masterpassDataService');
var categoryService = require('./services/categoryService');
var core = require('../core');

var starter = angular.module('starter', ['LocalStorageModule', accountService, appService, braintreeService, cartService, categoryService, checkoutService, configurationManager, core, localListManagerService, masterpassDataService, masterpassService, paypalService, quickCartService, simplifyService, stripeService, traceManager, uiRouter, visaService]);


// attach the controllers to this module
require('./appController')(starter);
require('./routes')(starter);

module.exports = starter;

var isFunction = function(o) {
  return typeof o == 'function';
};

var bind,
  slice = [].slice,
  proto = Function.prototype,
  featureMap;

featureMap = {
  'function-bind': 'bind'
};

function has(feature) {
  var prop = featureMap[feature];
  return isFunction(proto[prop]);
}

// check for missing features
if (!has('function-bind')) {
  // adapted from Mozilla Developer Network example at
  // https://developer.mozilla.org/en/JavaScript/Reference/Global_Objects/Function/bind
  bind = function bind(obj) {
    var args = slice.call(arguments, 1),
      self = this,
      nop = function() {
      },
      bound = function() {
        return self.apply(this instanceof nop ? this : (obj || {}), args.concat(slice.call(arguments)));
      };
    nop.prototype = this.prototype || {}; // Firefox cries sometimes if prototype is undefined
    bound.prototype = new nop();
    return bound;
  };
  proto.bind = bind;
}
