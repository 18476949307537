'use strict';
var angular = require('angular');
var accountService = angular.module('accountServiceProvider', []);


var config = {
	"STORAGE_KEY" : "accountInfo",
	"INIT_DATA" : {
		"currentUser" : null,
		"accounts" : {
			'jsmith@mc.com' : {
				'name' : 'John Smith',
				'email' : 'jsmith@mc.com'
			},
			'eric@unboundcommerce.com' : {
				'name' : 'Eric',
				'email' : 'eric@unboundcommerce.com'
			}
		}
	}
};


var factory = function (localStorage, configurationManager, accountServiceConfig) {
	var accountInfo = localStorage.get(accountServiceConfig.STORAGE_KEY);
	if(!accountInfo){
		accountInfo = accountServiceConfig.INIT_DATA;
		localStorage.add(accountServiceConfig.STORAGE_KEY, accountInfo);
	}

	return {
		/**
		 * Login function returns true or false if logged in
		 */
		login : function(username){
			if(this._userExists(username)){
				this._setCurrentUser(username);
				return true;
			}
			return false;
		},

		logout : function(){
			this._setCurrentUser(null);
		},

		isLoggedIn : function(){
			if(this._getCurrentUser()){
				return true;
			}else{
				return false;
			}
		},
		getName : function(){
			if(this.isLoggedIn()){
				return this._getCurrentUser().name;
			}
		},
		getEmail : function(){
			if(this.isLoggedIn()){
				return this._getCurrentUser().email;
			}
		},


		/* Internal properties and methods */

		_data : accountInfo,

		_getCurrentUser : function(){
			return this._data.accounts[this._data.currentUser];
		},
		_setCurrentUser : function(username){
			this._data.currentUser = username;
			this._saveData();
		},

		_userExists : function(username){
			if(this._data.accounts[username]){
				return true;
			}else{
				return false;
			}
		},

		_saveData : function(){
			localStorage.add(accountServiceConfig.STORAGE_KEY, this._data);
		}

	};
};

accountService.constant('accountServiceConfig', config).factory('accountService', ['localStorageService', 'configurationManager', 'accountServiceConfig', factory]);
module.exports = accountService.name;
