module.exports = function (module) {
	var callbackController = require('./modules/callback')(module);
	var cartController = require('./modules/cart')(module);
	var categoryController = require('./modules/category')(module);
	var checkoutController = require('./modules/checkout')(module);
	var returnCheckoutController = require('./modules/returnCheckout')(module);
	var confirmController = require('./modules/confirm')(module);
	var homeController = require('./modules/home')(module);
	var accountController = require('./modules/account')(module);
	var loaderController = require('./modules/loader')(module);
	var productController = require('./modules/product')(module);
	var productsController = require('./modules/products')(module);
	var productListingController = require('./modules/productListing')(module);
	var reviewController = require('./modules/review')(module);
	var sidebarController = require('./modules/sidebar')(module);
	var navbarController = require('./modules/navbar')(module);
	var popupCartController = require('./modules/popupCart')(module);
	var traceController = require('./modules/trace')(module);
	var configurationController = require('./modules/configuration')(module);

		//var callbackv7Controller = require('./modules/callbackv7')(module);
	module.config(function ($stateProvider, $urlRouterProvider) {
		$urlRouterProvider
			.when('/', '/home')
			.when('', '/home')

		$stateProvider
			.state('products', {
				url: '?:catId&:searchTerms&:products&:page&:size&:sort&:facets',
				templateUrl: 'tmpl/products.html',
				resolve: {
					facetResource: 'FacetData',
					facets: function(facetResource, $stateParams){
						return facetResource.get({ 
							catId: $stateParams.catId,
							searchTerms: $stateParams.searchTerms
						}).$promise;
					},
					categoryPathResource: 'CategoryPath',
					categoryPath: function(categoryPathResource, $stateParams){
						if($stateParams.catId){
							return categoryPathResource.query({
								catId: $stateParams.catId
							}).$promise;
						}
						else {
							return null;
						}
					}
				},
				controller: productsController
			})
			.state('products.productListing', {
				url: '/products',
				templateUrl: 'tmpl/productListing.html',
				resolve: {
					catalogResource: 'CatalogData',
					catalog: function(catalogResource, $stateParams){
						return catalogResource.get({ 
							catId: $stateParams.catId,
							page: $stateParams.page,
							size: $stateParams.size,
							sort: $stateParams.sort,
							searchTerms: $stateParams.searchTerms,
							facets: $stateParams.facets
						}).$promise;
					}
				},
				controller: productListingController
			})
			.state('product', {
				url: '/product?:productId',
				templateUrl: 'tmpl/product.html',
				resolve: {
					productResource: 'ProductData',
					product: function(productResource, $stateParams){
						return productResource.get({ productId: $stateParams.productId}).$promise;
					}
				},
				controller: productController
			})
			.state('cart', {
				url: '/cart',
				templateUrl: 'tmpl/cart.html'
				//controller: cartController
			})
			.state('home', {
				url: '/home',
				templateUrl: 'tmpl/home.html',
				controller: homeController
			})
			.state('category', {
				url: '/category?:catId',
				templateUrl: 'tmpl/category.html',
				resolve: {
					catId: function($stateParams){
						return $stateParams.catId;
					},
					categoryResource: 'CategoryData',
					category: function(categoryResource, $stateParams){
						return categoryResource.get({ catId: $stateParams.catId}).$promise;
					}
				},
				controller: categoryController
			})
			.state('checkout', {
				url: '/checkout',
				templateUrl: 'tmpl/checkout.html',
				//controller: checkoutController
			})
			.state('returnCheckout', {
				url: '/returnCheckout',
				templateUrl: 'tmpl/returnCheckout.html',
				controller: returnCheckoutController
			})
			.state('review', {
				url: '/review',
				templateUrl: 'tmpl/review.html',
				controller: reviewController
			})
			.state('paypalReturn', {
				url: '/paypalReturn?:token&:payerID',
				templateUrl: 'tmpl/callback.html',
				controller: callbackController,
				resolve : {
					masterpassCallbackResponsev7 : function($stateParams){
						return null;
					}
				},

			})
			.state('paypalRestReturn', {
				url: '/paypalRestReturn?:token&:payerID&:guid&:paymentId&:PayerID',
				templateUrl: 'tmpl/callback.html',
				controller: callbackController,
				resolve : {
					paypalRestReturnResponse : function($stateParams){
						return {
							guid : $stateParams.guid,
							paymentId : $stateParams.paymentId,
							PayerID : $stateParams.PayerID
						}
					},
					paypalReturnResponse : function($stateParams){
						return {
							token : $stateParams.token,
							payerID : $stateParams.payerID
						}
					},
					action : function($stateParams){
						return null;
					},
					masterpassCallbackResponse : function($stateParams){
						return null;
					}
				},

			})
			.state('account', {
				url: '/account',
				templateUrl: 'tmpl/account.html',
				controller: accountController
			})
			.state('callbackV7', {
				url: '/callbackv7?:oauth_token&:mpstatus',
				templateUrl: 'tmpl/callbackv7.html',/*			
				resolve : {
					masterpassCallbackResponsev7 : function($stateParams){
						return {
								mpstatus : $stateParams.mpstatus,
								oauth_token : $stateParams.oauth_token,
							}
					}
				},*/
   				controller: function($state,checkoutService){
   				var res=window.location.href;
   				var temp = res.split("&mpstatus=");
   				var mpstatus=temp[1].split('#')
   				if (mpstatus[0]=='success'){
   					temp=temp[0].split("&");
   					console.log(temp)
   					var oauth_token=temp[0].split('oauth_token=');
   					//console.log(oauth_token);
   					oauth_token=oauth_token[1];
   					var oauth_verifier=temp[1].split('oauth_verifier=');
   					//oauth_verifier=oauth_verifier[0];
   					var checkoutId=temp[2].split('checkoutId=');
   					//checkoutId=checkoutId[0];  
   					var checkout_resource_url=temp[3].split('checkout_resource_url=');
   					
   					checkoutService.masterpassV7CheckoutCallback(oauth_token,oauth_verifier[1],checkout_resource_url[1]);
   					//checkout_resource_url=checkout_resource_url[0];    					 
   					/*					
   					console.log("Transaction success");
   					console.log(oauth_token);
    				console.log(oauth_verifier[1]);
    				console.log(checkoutId[1]);
     				console.log(checkout_resource_url[1]);
     				*/

   				}
   				else{
   					console.log("Transaction cancelled");
   					checkoutService.masterpassCheckoutFailureCallback(mpstatus[0]);
   				}
   				//var mpstatus=res1[],
   				//console.log($stateParams);

   				//console.log($stateParams.oauth_token);
   				//console.log($stateParams.mpstatus)
			       //*** Exists! ***//
   				}
			})
			.state('callback', {
				url: '/callback/:action?:mpstatus&:oauth_token&:oauth_verifier&:checkout_resource_url&:pairing_token&:pairing_verifier',
				templateUrl: 'tmpl/callback.html',
				resolve : {
					paypalReturnResponse : function($stateParams){
						return null;
					},
					paypalRestReturnResponse : function($stateParams){
						return null;
					},
					action : function($stateParams){
						return $stateParams.action
					},

					masterpassCallbackResponse : function($stateParams){
						return {
								mpstatus : $stateParams.mpstatus,
								oauth_token : $stateParams.oauth_token,
								oauth_verifier : $stateParams.oauth_verifier,
								checkout_resource_url : $stateParams.checkout_resource_url,
								pairing_token : $stateParams.pairing_token,
								pairing_verifier : $stateParams.pairing_verifier
							}
					}
				},
				controller: callbackController
			})
			.state('confirm', {
				url: '/confirm',
				templateUrl: 'tmpl/confirm.html',
				controller: confirmController
			});
	});

   module.filter('split', function() {
        return function(input, splitChar, splitIndex) {
            // do some bounds checking here to ensure it has that index
            return input.split(splitChar)[splitIndex];
        }
    })

	.filter('encode', function() {
		return function(value){
			return encodeURIComponent(value);
		};
	})

	.filter('orderObjectBy', function() {
	  return function(items, field, reverse) {
	    var filtered = [];
	    angular.forEach(items, function(item) {
	      filtered.push(item);
	    });
	    filtered.sort(function (a, b) {
	      return (a[field] > b[field] ? 1 : -1);
	    });
	    if(reverse) filtered.reverse();
	    return filtered;
	  };
	})

	.filter('capitalize', function() {
		return function(input, all) {
			var reg = (all) ? /([^\W_]+[^\s-]*) */g : /([^\W_]+[^\s-]*)/;
			return (!!input) ? input.replace(reg, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();}) : '';
		}
	})

	.filter('camelCaseToHuman', function() {
  	return function(input) {
    		return input.charAt(0).toUpperCase() + input.substr(1).replace(/[A-Z]/g, ' $&');
  		}
	})

	.filter('traceUrl', function() {
		return function(input) {
				try{
					var url = new URL(input);
					var result = '<span class=location>' + url.pathname;
					if(url.search){
						result += url.search;
					}
					if(url.hash){
						result += url.hash;
					}
					result += '</span>';
					result += '<span class=hostname>' + url.origin + '</span>'
					return result;
				}catch(err){
					return input;
				}
			}
	})

	.filter('sanitizeAsHtml', function($sce) {
		return $sce.trustAsHtml;
	})

	.factory('CatalogData', function ($resource){
		//TODO: externalize endpoint
		return $resource('https://www.progamehub.com/mtt/catalog/search/products', {
			catId: '@_catId',
			page: '@_page',
			size: '@_size',
			sort: '@_sort',
			searchTerms: '@_searchTerms',
			facets: '@_facets',
			trim: true
		});
	})

	.factory('FacetData', function ($resource){
		//TODO: externalize endpoint
		return $resource('https://www.progamehub.com/mtt/catalog/search/facets', {
			catId: '@_catId',
			searchTerms: '@_searchTerms'
		});
	})

	.factory('ProductData', function ($resource){
		//TODO: externalize endpoint
		return $resource('https://www.progamehub.com/mtt/catalog/search/product', {
			productId: '@_productId'
		});
	})

	.factory('CategoryData', function ($resource){
		//TODO: externalize endpoint
		return $resource('https://www.progamehub.com/mtt/catalog/search/category', {
			catId: '@_catId'
		});
	})

	.factory('CategoryPath', function ($resource){
		//TODO: externalize endpoint
		return $resource('https://www.progamehub.com/mtt/catalog/search/categoryPath', {
			catId: '@_catId'
		});
	});

	module.run(function($rootScope){

	});
};
