'use strict';
var angular = require('angular');
var masterpassService = angular.module('masterpassService', []);


var config = {
	'BASE_URL': 'https://www.progamehub.com/mtt',
	'BASE_URL1': '',
	'CHECKOUT': '/masterpass/lightbox/checkout',
	'CHECKOUTV7_CALLBACK': '/masterpass/lightbox/checkoutV7Callback',
	'CHECKOUT_CALLBACK': '/masterpass/lightbox/checkoutCallback',
	'CONNECT': '/masterpass/lightbox/connect',
	'CONNECT_CALLBACK': '/masterpass/lightbox/connectCallback',
	'CONNECT_CHECKOUT': '/masterpass/lightbox/connectCheckout',
	'CONNECT_CHECKOUT_CALLBACK': '/masterpass/lightbox/connectCheckoutCallback',
	'PRECHECKOUT': '/masterpass/precheckoutData',
	'RETURN_CHECKOUT': '/masterpass/lightbox/connectedCheckout',
	'RETURN_CHECKOUT_CALLBACK': '/masterpass/connectedCheckoutCallback',
	'EXPRESS_CHECKOUT' : '/masterpass/expressCheckout',
	'POSTBACK' : '/masterpass/postback',
	'CHECKOUT_TYPE' : {
		'STANDARD' : 'Standard',
		'CONNECT' : 'Connect',
		'EXPRESS' : 'Express'
	}
}

/**
 * Service for handling all MasterPass-related API calls.
 * NOTE: These functions will turn on the loader while waiting on an API call, but they do not turn off the loader.
 *       The callback functions passed in are expected to handle the loading state.
 */

var factory = function (app, masterpassDataService, configurationManager, masterpassServiceConfig, $http, traceManager) {
	return {
		CHECKOUT_TYPE : masterpassServiceConfig.CHECKOUT_TYPE,

	checkout : function(successCallback, failureCallback, cartContainer, preventPairingRequest){
			console.log("************************checkout**********************");
			var self = this;
			/*
			var internalSuccessCallback = function(masterpassApiResponse){
				self.checkoutCallback(masterpassApiResponse, successCallback, failureCallback, cartContainer);
			}
			var internalFailureCallback = function(masterpassApiResponse){
				console.log("No failureCallback defined for masterpass.checkout.\n%o", masterpassApiResponse);
				failureCallback(null);
			}
			*/
			var requestUrl;
			console.log("cartContainer");
					requestUrl = 'https://www.progamehub.com/#/callbackv7';

					var cartIdNum = Math.floor(Math.random() * (200000 - 100000 + 1)) + 100000;
						var init = {
        				checkoutId: configurationManager.state.checkoutIdentifier,                                   // Merchant checkout identifier received during when merchant onboarded for masterpass
        				allowedCardTypes: configurationManager.state.acceptedCards,               // Card types accepted by merchant
        				amount: cartContainer.totalPrice.toFixed(2),                                                                // Shopping cart subtotal
        				currency: "USD",                                                                  // Currency code for cart
        				shippingLocationProfile: configurationManager.state.shippingProfiles,                                            // Shipping locations supported by merchant - configured in merchant portal
        				suppress3Ds: false,                                                               // Set true when 3DS not mandatory for the spcecific country
        				suppressShippingAddress: configurationManager.state.shippingSuppression,                                                   // Set true when cart items has digital goods only
        				cartId: cartContainer.cartId,                                    // Unique identifier for cart generated by merchant
                          // The URL to which the browser must redirect when checkout is complete
        				callbackUrl: requestUrl
					};
					console.log(init);
					window.setTimeout(console.log(""), 5000);
						//	self._addLightboxTrace('masterpass.checkout()', init);
					app.loading = false;
					masterpass.checkout(init);

				},

			/*
				// standard checkout
				requestUrl = masterpassServiceConfig.BASE_URL + masterpassServiceConfig.CHECKOUT;

			var requestParameters = masterpassDataService.getMasterpassData(cartContainer);
			requestParameters.callbackUrl = requestParameters.callbackDomain + requestParameters.checkoutCallbackPath;

			var requestBody = self._getShoppingCart(cartContainer);
					requestUrl = masterpassServiceConfig.BASE_URL + masterpassServiceConfig.CHECKOUT_CALLBACK;

					var cartIdNum = Math.floor(Math.random() * (200000 - 100000 + 1)) + 100000;
						var init = {
        				checkoutId: configurationManager.state.checkoutIdentifier,                                   // Merchant checkout identifier received during when merchant onboarded for masterpass
        				allowedCardTypes: configurationManager.state.acceptedCards,               // Card types accepted by merchant
        				amount: cartContainer.totalPrice.toFixed(2),                                                                // Shopping cart subtotal
        				currency: "USD",                                                                  // Currency code for cart
        				shippingLocationProfile: configurationManager.state.shippingProfiles,                                            // Shipping locations supported by merchant - configured in merchant portal
        				suppress3Ds: false,                                                               // Set true when 3DS not mandatory for the spcecific country
        				suppressShippingAddress: configurationManager.state.shippingSuppression,                                                   // Set true when cart items has digital goods only
        				cartId: cartIdNum.toString(),                                    // Unique identifier for cart generated by merchant
                          // The URL to which the browser must redirect when checkout is complete
        				callbackUrl: requestUrl
					};


			app.loading = true;
			$http.post(requestUrl, requestBody, { params : requestParameters }).then(
				function(successResponse){
						requestUrl = masterpassServiceConfig.BASE_URL + masterpassServiceConfig.CHECKOUT_CALLBACK;
						console.log(successResponse);
						masterpassDataService.setMasterpassData(successResponse.data);
						var cartIdNum = Math.floor(Math.random() * (200000 - 100000 + 1)) + 100000;




						var init = {
        				checkoutId: configurationManager.state.checkoutIdentifier,                                   // Merchant checkout identifier received during when merchant onboarded for masterpass
        				allowedCardTypes: configurationManager.state.acceptedCards,               // Card types accepted by merchant
        				amount: cartContainer.totalPrice.toFixed(2),                                                                // Shopping cart subtotal
        				currency: "USD",                                                                  // Currency code for cart
        				shippingLocationProfile: configurationManager.state.shippingProfiles,                                            // Shipping locations supported by merchant - configured in merchant portal
        				suppress3Ds: false,                                                               // Set true when 3DS not mandatory for the spcecific country
        				suppressShippingAddress: configurationManager.state.shippingSuppression,                                                   // Set true when cart items has digital goods only
        				cartId: cartIdNum.toString(),                                    // Unique identifier for cart generated by merchant
                          // The URL to which the browser must redirect when checkout is complete
        				callbackUrl: requestUrl
					};
			/*var init = {
				        checkoutId: configurationManager.state.checkoutIdentifier,                                   // Merchant checkout identifier received during when merchant onboarded for masterpass
        allowedCardTypes: configurationManager.state.acceptedCards,               // Card types accepted by merchant
        amount: cartContainer.totalPrice.toFixed(2),                                                                 // Shopping cart subtotal
        currency: configurationManager.state.currencyCode,                                                                  // Currency code for cart
        shippingLocationProfile: configurationManager.state.shippingProfiles,                                              // Shipping locations supported by merchant - configured in merchant portal
        suppress3Ds: false,                                                               // Set true when 3DS not mandatory for the spcecific country
        suppressShippingAddress: configurationManager.state.shippingSuppression,                                                   // Set true when cart items has digital goods only
        cartId: cartIdNum.toString(),                                   // Unique identifier for cart generated by merchant
        callbackUrl: requestUrl
			};*/

		checkoutV7Callback : function(successCallback, failureCallback,oauth_token,oauth_verifier,checkout_resource_url,cartContainer){
			console.log("************************checkoutCallback**********************");
			// handle storage of response
			/*
			this._updateLightboxTrace(masterpassApiResponse);	*/
			masterpassDataService.setLightboxResponseV7(oauth_token,oauth_verifier,checkout_resource_url);

					// standard checkout
				var requestUrl = masterpassServiceConfig.BASE_URL + masterpassServiceConfig.CHECKOUTV7_CALLBACK;
				var requestParameters = masterpassDataService.getMasterpassData(cartContainer);
				console.log(requestParameters);
				app.loading = true;
				$http.get(requestUrl, { params : requestParameters }).then(
					function(successResponse){
						console.log(successResponse.data);

						// store response
						//masterpassDataService.setMasterpassData(successResponse.data, true);
						// checkout data retreived. initiate success callback
						successCallback(successResponse.data);
					},
					function(failureResponse){
						//console.log("fdone");
						failureCallback(null);
					}
				);


		},

		checkoutCallback : function(masterpassApiResponse, successCallback, failureCallback, cartContainer){
			console.log("************************checkoutCallback**********************");
			// handle storage of response
			this._updateLightboxTrace(masterpassApiResponse);
			masterpassDataService.setLightboxResponse(masterpassApiResponse);

			// perform next steps
			if(masterpassApiResponse.mpstatus == "success"){
				// call checkout data
				var requestUrl;
				if(configurationManager.state.checkoutType == masterpassServiceConfig.CHECKOUT_TYPE.CONNECT || configurationManager.state.checkoutType == masterpassServiceConfig.CHECKOUT_TYPE.EXPRESS){
					// pair during checkout
					requestUrl = masterpassServiceConfig.BASE_URL + masterpassServiceConfig.CONNECT_CHECKOUT_CALLBACK;
				}else{
					// standard checkout
					requestUrl = masterpassServiceConfig.BASE_URL + masterpassServiceConfig.CHECKOUT_CALLBACK;
				}

				var requestParameters = masterpassDataService.getMasterpassData(cartContainer);

				app.loading = true;
				$http.get(requestUrl, { params : requestParameters }).then(
					function(successResponse){
						// store response
						masterpassDataService.setMasterpassData(successResponse.data, true);
						// checkout data retreived. initiate success callback
						successCallback(successResponse.data);
					},
					function(failureResponse){
						failureCallback(null);
					}
				);

			}else{
				failureCallback(null);
			}
		},

		/**
		 * performs pairing request
		 * @param successCallback : function - called on successful pairing and passed MasterpassData object
		 * @param failureCallback : function - called on API or Lightbox failure
		 */
		connect : function(successCallback, failureCallback, cartContainer){
			console.log("**********************connect************************");
			var self = this;
			var internalSuccessCallback = function(masterpassApiResponse){
				self.connectCallback(masterpassApiResponse, successCallback, failureCallback, cartContainer);
			}
			var internalFailureCallback = function(masterpassApiResponse){
				console.log("No failureCallback defined for masterpass.checkout.\n%o", masterpassApiResponse);
				failureCallback(null);
			}

			// clear stale values
			self.clearCheckoutData();

			var requestUrl = masterpassServiceConfig.BASE_URL + masterpassServiceConfig.CONNECT;

			var requestParameters = masterpassDataService.getMasterpassData(cartContainer);
			requestParameters.callbackUrl = requestParameters.callbackDomain + requestParameters.pairingCallbackPath;

			app.loading = true;
			$http.get(requestUrl, { params : requestParameters }).then(
				function(successResponse){
					masterpassDataService.setMasterpassData(successResponse.data);
					// initiate lightbox
					var init = {
						pairingRequestToken : successResponse.data.pairingToken,
						merchantCheckoutId : successResponse.data.checkoutIdentifier,
						callbackUrl : successResponse.data.callbackUrl,
						successCallback : internalSuccessCallback,
						failureCallback : internalFailureCallback,
						requestedDataTypes: successResponse.data.pairingDataTypes,
						requestPairing: true,
						requestExpressCheckout: configurationManager.state.checkoutType == masterpassServiceConfig.CHECKOUT_TYPE.EXPRESS,
						version : successResponse.data.xmlVersion
					};

					self._addLightboxTrace('MasterPass.client.connect()', init);

					app.loading = false;
					MasterPass.client.connect(init);
				},
				function(failureResponse){
					failureCallback(null);
				}
			);
		},

		/**
		 * handles response from MasterPass Lightbox for a pairing request.  Turns on loader so callbacks must handle truning off the loader
		 * @param masterpassApiResponse : object
		 * @param successCallback : function
		 * @param failureCallback : function
		 */
		connectCallback : function(masterpassApiResponse, successCallback, failureCallback, cartContainer){
			console.log("************************connectCallback**********************");
			// store response data
			this._updateLightboxTrace(masterpassApiResponse);
			masterpassDataService.setLightboxResponse(masterpassApiResponse);

			if(masterpassApiResponse.mpstatus == "success"){
				// call checkout data
				var requestUrl = masterpassServiceConfig.BASE_URL + masterpassServiceConfig.CONNECT_CALLBACK;

				var requestParameters = masterpassDataService.getMasterpassData(cartContainer);

				app.loading = true;
				$http.get(requestUrl , { params : requestParameters }).then(
					function(successResponse){
						// callback
						masterpassDataService.setMasterpassData(successResponse.data, true);
						// long access retrieved
						successCallback(successResponse.data);
					},
					function(failureResponse){
						failureCallback(null);
					});
			}else{
				failureCallback(null);
			}
		},


		/**
		 * get precheckout data
		 * @param callback : function - passed precheckout data or null on failure
		 */
		precheckoutData : function(callback, cartContainer){
			var self = this;
			console.log("**********************precheckoutData************************");
			// check long-access token exists
			if(!configurationManager.state.longAccessToken){
				callback(null);
				return;
			}

			var requestUrl = masterpassServiceConfig.BASE_URL + masterpassServiceConfig.PRECHECKOUT;

			var requestParameters = masterpassDataService.getMasterpassData(cartContainer);

			// get precheckout data
			app.loading = true;
			$http.get(requestUrl, { params : requestParameters }).then(
				function(successResponse){
					console.log("PRecheckoutData: %s %s", successResponse.status, successResponse.statusText);
					// store precheckout info
					masterpassDataService.setMasterpassData(successResponse.data);

					callback(successResponse.data);
				},
				function(failureResponse){
					console.log("PRecheckoutData: %s %s", failureResponse.status, failureResponse.statusText);
					// remove invalid long access token and precheckout values
					self.clearPairingData();

					callback(null);
				}
			);
		},


		/**
		 * initiate the connected checkout buy button to get checkout resource
		 * @param buttonContiainer : String - ID of the container to add the Buy with MasterPass button
		 * @param successCallback : function - function to call on sucessful retrieval of checkout passed MasterpassData as argument
		 * @param failureCallback : function - The callback that is passed to the lightbox init that is called on lightbox failure
		 */
		returnCheckout : function(buttonContainer, successCallback, failureCallback, cartContainer){
			console.log("*************************returnCheckout*********************");
			var self = this;
			var internalCartContainer = cartContainer;

			var requestUrl = masterpassServiceConfig.BASE_URL + masterpassServiceConfig.RETURN_CHECKOUT;
			var requestParameters = masterpassDataService.getMasterpassData(cartContainer);
			requestParameters.callbackUrl = requestParameters.callbackDomain + requestParameters.connectedCallbackPath;
			var requestBody = self._getShoppingCart(cartContainer);

			var internalSuccessCallback = function(masterpassApiResponse){
				console.log(internalCartContainer);
				self.returnCheckoutCallback(
					masterpassApiResponse,
					successCallback,
					internalCartContainer,
					(function(){return cartContainer})() // needed to pass cartContainer into function
				);
			}
			var internalFailureCallback = function(masterpassApiResponse){
				failureCallback(null);
			}


			// get return checkout button
			app.loading = true;
			$http.post(requestUrl, requestBody, { params : requestParameters }).then(
				function(successResponse){
					// store precheckout info
					masterpassDataService.setMasterpassData(successResponse.data, true);

					// initiate lightbox
					var init = {
						callbackUrl: successResponse.data.callbackUrl,
						successCallback : internalSuccessCallback,
						failureCallback : internalFailureCallback,
						consumerWalletId : successResponse.data.consumerWalletId,
						walletName : successResponse.data.walletName,
						cardId : successResponse.data.precheckoutCardId,
						shippingId : successResponse.data.precheckoutShippingId,
						merchantCheckoutId : successResponse.data.checkoutIdentifier,
						requestBasicCheckout : successResponse.data.authLevelBasic,
						requestToken : successResponse.data.requestToken,
						requireShippingDestination : successResponse.data.shippingSuppression,
						precheckoutTransactionId : successResponse.data.precheckoutTransactionId,
						version: successResponse.data.xmlVersion,
						element: buttonContainer
					};

					// set rewards details
					if(successResponse.data.rewards){
						init.loyaltyId = successResponse.data.precheckoutLoyaltyId;
					}

					self._addLightboxTrace('MasterPass.client.checkoutButton()', init);

					app.loading = false;
					MasterPass.client.checkoutButton(init);
				},
				function(){
					failureCallback(null);
				}
			);
		},

		/**
		 * handles response from MasterPass Lightbox for return checkout place order. Turns on loader, so callbacks must handle loader
		 * @param masterpassApiResponse : object
		 * @param successCallback : function
		 * @param failureCallback : function
		 */
		returnCheckoutCallback : function(masterpassApiResponse, successCallback, failureCallback, cartContainer){
			console.log("**********************returnCheckoutCallback************************");
			// store response data
			this._updateLightboxTrace(masterpassApiResponse);
			masterpassDataService.setLightboxResponse(masterpassApiResponse);

			// perform next steps
			if(masterpassApiResponse.mpstatus == "success"){
				// call checkout data
				var requestUrl = masterpassServiceConfig.BASE_URL + masterpassServiceConfig.RETURN_CHECKOUT_CALLBACK;

				var requestParameters = masterpassDataService.getMasterpassData(cartContainer);

				app.loading = true;
				$http.get(requestUrl, { params : requestParameters }).then(
					function(successResponse){
						// store response
						masterpassDataService.setMasterpassData(successResponse.data, true);
						// checkout data retreived. initiate success callback
						successCallback(successResponse.data);
					},
					function(failureResponse){
						failureCallback(null);
					}
				);

			}else{
				failureCallback(null);
			}
		},

		/**
		 * retrieves checkout resource for an express checkout.  Initiates 3DS if needed
		 * @param successCallback : function - Called on successful retrieval of checkout resource. Passed MasterpassData object.
		 * @param failureCallback : function - The callback that is passed to the lightbox init that is called on security lightbox failure.  Also called on failure to retrieve checkout resource.
		 */
		expressCheckout : function(successCallback, failureCallback, cartContainer){
			console.log("**********************expressCheckout************************");
			var self = this;
			var requestUrl = masterpassServiceConfig.BASE_URL + masterpassServiceConfig.EXPRESS_CHECKOUT;
			var requestParameters = masterpassDataService.getMasterpassData(cartContainer);
			requestParameters.callbackUrl = requestParameters.callbackDomain + requestParameters.expressCallbackPath;
			var requestBody = self._getExpressCheckoutRequest(cartContainer);

			var internalFailureCallback = function(masterpassApiResponse){
				console.log("MasterPass API: 3DS failure | %o", masterpassApiResponse);
				failureCallback(null);
			};
			var internalSuccessCallback = function(masterpassApiResponse){
				self.express3dsCallback(masterpassApiResponse, successCallback, failureCallback, cartContainer);
			};


			// get express checkout data
			app.loading = true;
			$http.post(requestUrl, requestBody, { params : requestParameters }).then(
				function(successResponse){
					// store express checkout info
					masterpassDataService.setMasterpassData(successResponse.data, true);

					if(successResponse.data.expressSecurityRequired){
						app.loading = false;
						var init = {
							"callbackUrl": successResponse.data.callbackUrl,
							"merchantCheckoutId": successResponse.data.checkoutIdentifier,
							"successCallback" : internalSuccessCallback,
							"failureCallback" : internalFailureCallback,
							"precheckoutTransactionId": successResponse.data.precheckoutTransactionId
						}

						self._addLightboxTrace('MasterPass.client.cardSecurity()', init);

						MasterPass.client.cardSecurity(init);

					}else{
						if(successResponse.data.expressCheckoutResponseData.checkout){
							// remove precheckout data as it is now stale
							masterpassDataService.setProperties({preCheckoutData : null});

							successCallback(successResponse.data);
						}else{
							// express checkout failed
							console.log("Express Checkout Data: no checkout resource returned");
							failureCallback(successResponse.data);
						}
					}
				},
				function(failureResponse){
					failureCallback(null);
				}
			);
		},
		express3dsCallback : function(masterpassApiResponse, successCallback, failureCallback, cartContainer){
			console.log("*******************express3dsCallback***************************");
			console.log("MasterPass API: 3DS success | %o", masterpassApiResponse);
			masterpassDataService.setLightboxResponse(masterpassApiResponse);
			this._updateLightboxTrace(masterpassApiResponse);

			if(masterpassApiResponse.mpstatus == 'success'){
				this.expressCheckout(successCallback, failureCallback, cartContainer);
			}else{
				failureCallback(null);
			}
		},


		postback : function(cartContainer){
			var self = this;
			console.log("***********************postback***********************");
			var requestUrl = masterpassServiceConfig.BASE_URL + masterpassServiceConfig.POSTBACK;
			var requestParameters = masterpassDataService.getMasterpassData(cartContainer);
			var requestBody = self._getShoppingCart(cartContainer);

			// send postback request
			$http.post(requestUrl, requestBody, {params : requestParameters}).then(
				function(successResponse){
					console.log('Postback: %o', successResponse.data);
					masterpassDataService.setMasterpassData(successResponse.data);
				},
				function(failureResponse){
					console.log('Postback: %o', failureResponse.data);
				}
			);
		},

		clearCheckoutData : function(){
			console.log("********************clearCheckoutData**************************");
			masterpassDataService.setProperties({
				"checkout" : null,
				"transactionId" : null,
				"preCheckoutData" : null,
				"precheckoutCardId" : null,
				"precheckoutLoyaltyId" : null,
				"precheckoutShippingId" : null,
				"precheckoutTransactionId" : null,
				"expressCheckoutResponseData" : null
			});
		},

		clearPairingData : function(){
			console.log("***********************clearPairingData***********************");
			masterpassDataService.setProperties({
				"longAccessToken" : null,
				"preCheckoutData" : null,
				"precheckoutTransactionId" : null,
				"precheckoutCardId" : null,
				"precheckoutLoyaltyId" : null,
				"precheckoutShippingId" : null
			});
		},

		_pairingAllowed : function(){
			console.log("*******************_pairingAllowed***************************");
			return configurationManager.state.checkoutType != masterpassServiceConfig.CHECKOUT_TYPE.STANDARD
					&& (configurationManager.state.pairingType == 'Always' || !configurationManager.state.longAccessToken)
		},

		_addLightboxTrace : function(functionName, lightboxParameters){
			console.log("************************_addLightboxTrace**********************");
			var callTrace = [{
				"url" : functionName,
				"lightboxParameters" : lightboxParameters,
				"responseBody" : "N/A"
			}]
			traceManager.save(callTrace);
		},

		_updateLightboxTrace : function(masterpassApiResponse){
			console.log("*************************_updateLightboxTrace*********************");
			if(traceManager.enabled){
				var trace = traceManager.callTraces[traceManager.callTraces.length -1];
				if(trace.lightboxParameters){
					trace.responseBody = masterpassApiResponse;
				}
			}
		},

		/**
		 * Creates a ShoppingCartShoppingCartRequest object
		 */
		_getShoppingCart : function(cartContainer){
			console.log("***********************_getShoppingCart***********************");
			var cartRequest = {
				"oauthToken": null,
				"originUrl": window.location.origin,
				"shoppingCart": {
					"shoppingCartItems" : [],
					"currencyCode": configurationManager.state.currencyCode,
					"subtotal": (cartContainer.subtotal * 100).toFixed()
				}
			};

			// populate
			var products = cartContainer.products;
			for(var id in products){
				var product = products[id];
				if(product && product.productId){
					var price = product.salePrice || product.price;
					var itemValue = Math.round(price * 100 * product.cartQuantity);

					var shoppingCartItem = {
						"description": product.name,
						"quantity":product.cartQuantity,
						"value": itemValue
					}
					if(product.image){
						shoppingCartItem.imageURL = product.image;
					}

					cartRequest.shoppingCart.shoppingCartItems.push(shoppingCartItem);
				}
			}

			return cartRequest;
		},

		_getExpressCheckoutRequest : function(cartContainer){
			console.log("***********************_getExpressCheckoutRequest***********************");
			var expressCheckoutRequest = {
				"merchantCheckoutId" : configurationManager.state.checkoutIdentifier,
				"precheckoutTransactionId": configurationManager.state.precheckoutTransactionId,
				"currencyCode": configurationManager.state.currencyCode,
				"cardId" : configurationManager.state.precheckoutCardId,
				"originUrl" : configurationManager.state.callbackDomain,
				"advancedCheckoutOverride" : configurationManager.state.authLevelBasic,
				"orderAmount" : (cartContainer.totalPrice * 100).toFixed(),
				"digitalGoods" : cartContainer.digital == true
			};

			if(configurationManager.state.precheckoutShippingId){
				expressCheckoutRequest.shippingAddressId = configurationManager.state.precheckoutShippingId
			}

			if(configurationManager.state.precheckoutLoyaltyId){
				expressCheckoutRequest.rewardProgramId = configurationManager.state.precheckoutLoyaltyId;
			}

			//expressCheckoutRequest.extensionPoint

			return expressCheckoutRequest;
		}

	};
};

masterpassService.constant('masterpassServiceConfig', config).factory('masterpass', ['app', 'masterpassDataService', 'configurationManager', 'masterpassServiceConfig', '$http', 'traceManager', factory]);
module.exports = masterpassService.name;
