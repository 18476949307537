'use strict';

var controller = function ($scope, $http, app, categoryService, masterpass, cart, localStorageService, $location, logger, textBundle, userSessionStore) {

	logger('app controller');

	/* declaration */

	$scope.app = app;

	$scope.selectLanguage = selectLanguage;

	$scope.noScroll = noScroll;

	/* initalization */

	//define languages
	app.languages = [
		{label: 'English', value: 'en'},
		{label: 'French', value: 'fr'}
	];

	// initialize semi-global variables
	cart.getStoredCart();
	categoryService.setCategoryMap().then(function(response) {
		categoryService.categoryMap = response.data;
	});

	// setting default
	app.selectedLanguage = userSessionStore.get('selectedLanguage');

	if ($scope.languageDropdown == null) {
		$scope.languageDropdown = app.languages[0];
	}
	if (app.selectedLanguage == null) {
		app.selectedLanguage = $scope.languageDropdown.value;
	}

	textBundle.getBundle(app.selectedLanguage).then(function (result) {
		$scope.textBundle = result;
		logger($scope.textBundle);
	});


	/* implementation */

	function selectLanguage() {
		logger('Language is changed to ' + $scope.languageDropdown.value);

		app.selectedLanguage = $scope.languageDropdown.value;
		userSessionStore.set('selectedLanguage', app.selectedLanguage);

		textBundle.getBundle(app.selectedLanguage).then(function (result) {
			$scope.textBundle = result;
			logger($scope.textBundle);
		});
	};

	function noScroll(){
		return app.loading || app.showConfiguration || app.showTrace;
	}
	$scope.status = 'ready';
};

module.exports = function (module) {
	var name = 'appController';
	module.controller(name, controller);
	return name;
};
