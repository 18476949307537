'use strict';

var controller = function ($scope, app, catId, category, categoryService, $http, $location, logger, textBundle, userSessionStore, $state) {
	logger('category controller');
	$scope.app = app;
	$scope.imageLocation = '';
	categoryService.currentCategory = catId;
	app.currentPage = $state.current.name;
	$scope.category = category;

	$scope.status = 'ready';
};

module.exports = function (module) {
	var name = 'CategoryController';
	module.controller(name, controller);

	return name;
};