'use strict';

var controller = function ($scope, $window, app, cart, checkoutService, $http, $location, logger, localStorageService, masterpass, textBundle, userSessionStore, $state) {
	logger('review controller');
	$scope.app = app;
	$scope.cart = cart;
	app.currentPage = $state.current.name;
	var visaTimeout;

	// Retrieve previously entered user info from local storage 
	$scope.info = checkoutService.getInfo();

	$scope.cart = cart;

	$scope.isDigital = cart.cartContainer.digital;

	$scope.placeOrder = placeOrder;

	$scope.placeVCOrder = placeVCOrder;

	$scope.placeMPV7Order = placeMPV7Order;

	$scope.placePayPalOrder = placePayPalOrder;
	
	$scope.placeBraintreePayPalOrder = placeBraintreePayPalOrder;

	$scope.placeBraintreeOrder = placeBraintreeOrder;

	$scope.masterpassReturnCheckoutFlow = checkoutService.isMasterpassReturnCheckout();

	$scope.masterpassExpressCheckoutFlow = checkoutService.isMasterpassExpressCheckout();

	$scope.paypalExpressCheckoutFlow = checkoutService.isPayPalExpressCheckoutFlow();

	$scope.braintreeCheckoutFlow = checkoutService.isBraintreeExpressCheckoutFlow();

	$scope.braintreePayPalCheckoutFlow = checkoutService.isBraintreePayPal();

	$window.scrollTo(0,0);

	$scope.visaCheckoutFlow = checkoutService.isVisaCheckoutFlow();

	/* initalization */

	// If cart is empty, redirect to empty cart
	if(cart.cartContainer['cartCount'] == 0 || cart.cartContainer['cartCount'] == null){
		$state.go('cart');
	}

	checkoutService.initalizeReview();

	if(window.visaCheckoutLoaded){
		console.log("in checkout loaded")
		visaTimeout = window.setTimeout(visaCheckoutInit, 500);
	}

	/* implementation */

	function placeOrder(){
		checkoutService.placeOrder();
	}

	function placeVCOrder(){
		checkoutService.placeVisaCheckoutOrder();
	}

	function placeMPV7Order(){
		checkoutService.placeMasterPassV7CheckoutOrder();
	}

	function placePayPalOrder(){
		checkoutService.placePayPalExpressCheckoutOrder();
	}

	function placeBraintreePayPalOrder(){
		checkoutService.placeBraintreePayPalOrder();
	}

	function placeBraintreeOrder(){
		checkoutService.placeBraintreeOrder();
	}

	function visaCheckoutInit(){
		if(document.getElementById("editVisa") != null){
			V.init(
		     	{ 
		     		apikey: "IJSY3X3EQW978DQ0F73013TQlAfXPyLBlTWPm80OeSstPvqvk",
		     		referenceCallID: checkoutService.getInfo().callid,
		     		paymentRequest:{
	    				currencyCode: "USD",
	     				total: cart.cartContainer.totalPrice.toFixed(2)
					 },
					 settings:{
					 	dataLevel : "FULL"
					 }
		     	}
		    );
		    V.on("payment.success", function(payment) {
		    	console.log(JSON.stringify(payment));
		    	//make call to back end passing in payload for decryption
				var requestUrl = "https://www.progamehub.com/mtt/VisaCheckout/lightbox/callback";

				var requestParameters = payment;

				app.loading = true;
				$http.post(requestUrl, requestParameters).then(
					function(successResponse){
						console.log(successResponse);
						checkoutService.applyVisaCheckoutResource(successResponse.data, requestParameters.callid);
						// go to receipt page
						$window.location.reload();
						app.loading = false;
					},
					function(failureResponse){
						app.loading = false;
						console.log(failureResponse)
					}
				);
		    });
			V.on("payment.cancel", function(payment) {
				//shouldn't need to do anything here.
			});
			V.on("payment.error", function(payment,error){
				//handle errors here???
				console.log(JSON.stringify(error));
			});
			window.clearTimeout(visaTimeout);
		}
	}
	$scope.status = 'ready';
};

module.exports = function (module) {
	var name = 'ReviewController';
	module.controller(name, controller);

	return name;
};