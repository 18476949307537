'use strict';

var controller = function ($scope, app, $window, accountService, braintreeService, checkoutService, $http, $location, cart, localStorageService, logger, textBundle, userSessionStore, $state, configurationManager) {
	logger('cart controller');
	this.app = app;
	this.cart = cart;
	app.currentPage = $state.current.name;
	var visaTimeout;
	var stripeTimeout;
	var braintreeTimeout;
	/* declatation */

	$window.scrollTo(0,0);

	this.accountService = accountService;

	this.isLoggedIn = isLoggedIn;
	this.getCustomerName = getCustomerName;

	// Create list of cart items in pairs of 2,
	// Allows for each row to have 2 columns with the height of the taller one

	this.quantityMod = quantityMod;

	this.masterpass = masterpass;

	this.checkout = checkout;

	this.paypal = paypal;
	this.paypalRest = paypalRest;

	this.state = $state;

	/* initalization */
	if(window.visaCheckoutLoaded){
		console.log("in checkout loaded");
		visaTimeout = window.setTimeout(visaCheckoutInit, 300);
	}

/*	if(StripeCheckout){
		console.log("stripe checkout loaded");
		stripeTimeout = window.setTimeout(StripeCheckoutInit, 300);
	}*/

/*	if(braintree){
		console.log("stripe checkout loaded");
		braintreeTimeout = window.setTimeout(BraintreeInit, 300);
	}
*/
	/* implementation */

	function checkout(){
		console.log("in checkout ");
		checkoutService.startCheckout();
	}

	function quantityMod(productId, value) {
		console.log("in quantityMod");
		// Update quantity of cart item with value passed by template
		cart.updateCart(cart.cartContainer['products'].productId, value, productId);
	}

	function getCustomerName(){
		console.log("in getCustomerName");
		return accountService.getName();
	}

	function isLoggedIn(){
		console.log("in isLoggedIn");
		return accountService.isLoggedIn();
	}

	function masterpass(){
		console.log("in masterpass");
		checkoutService.startMasterpassCheckout();
	}

	function paypal(){
		console.log("in paypal");
		checkoutService.startPayPalCheckout();
	}

	function paypalRest(){
		console.log("in paypalRest");
		checkoutService.startPayPalRestCheckout();
	}

	function visaCheckoutInit(){
		if(document.getElementById("visaButton") != null){
			V.init(
		     	{
		     		apikey: "Y4YXRN9BTW3N82TK7QU214zgFk8Bazv0mi7WOX5vREaBxRHe8",
		     		paymentRequest:{
	    				currencyCode: "USD",
	     				total: cart.cartContainer.totalPrice.toFixed(2)
					 },
					 settings:{
					 	dataLevel : "FULL"
					 }
		     	}
		    );
		    V.on("payment.success", function(payment) {
		    	console.log(JSON.stringify(payment));
		    	//make call to back end passing in payload for decryption
				var requestUrl = "https://www.progamehub.com/mtt/VisaCheckout/lightbox/callback";

				var requestParameters = payment;

				app.loading = true;
				$http.post(requestUrl, requestParameters).then(
					function(successResponse){
						console.log(successResponse);
						checkoutService.applyVisaCheckoutResource(successResponse.data, requestParameters.callid);
						// go to receipt page
						$state.go("review");
						app.loading = false;
					},
					function(failureResponse){
						app.loading = false;
						console.log(failureResponse);
					}
				);
		    });
			V.on("payment.cancel", function(payment) {
				//shouldn't need to do anything here.
				//possibly analytics in a real environment
			});
			V.on("payment.error", function(payment,error){
				//handle errors here
				console.log(JSON.stringify(error));
			});
			console.log('visa checkout initialized');
			window.clearTimeout(visaTimeout);
		}
	}

	function StripeCheckoutInit(){
		console.log("in StripeCheckoutInit");
		var handler = StripeCheckout.configure({
			key: 'pk_test_xgXOSJWre21QN22iS5I7Q8mG',
			image: '/images/SmallLogo3x.png',
			locale: 'auto',
			billingAddress: true,
			shippingAddress: true,
			token: function(token, args) {
				//pass token to server side, set shipping/billing info with args data.
				app.loading = true;
				checkoutService.applyStripeCheckoutResource(args, token);
				checkoutService.createStripeCharge(token, (cart.cartContainer.totalPrice * 100).toFixed(), function(data){
					$state.go('confirm');
				});
			}
		});

		$('#customButton').on('click', function(e) {
			// Open Checkout with further options
			handler.open({
				name: 'ProGameHub',
				description: '',
				amount: (cart.cartContainer.totalPrice * 100).toFixed()
			});
			e.preventDefault();
		});

		// Close Checkout on page navigation
		$(window).on('popstate', function() {
			handler.close();
		});
	}

	function BraintreeInit(){
		console.log("in BraintreeInit");
		braintreeService.getClientToken(function(data){
			braintree.setup(data.clientToken, "custom", {
				paypal: {
					container: "paypal-container",
					singleUse: true,
					amount: cart.cartContainer.totalPrice.toFixed(2),
					currency: 'USD',
					enableShippingAddress: true
				},
				onPaymentMethodReceived: function (obj){
					console.log(obj);
					checkoutService.handleBraintreeNonce(obj, function(data){
						$state.go('review');
					});
				}
			})
		})
	}
	$scope.status = 'ready';
};

module.exports = function (module) {
	var name = 'CartController';
	module.controller(name, controller);

	return name;
};
