'use strict';
var angular = require('angular');
var braintreeService = angular.module('braintreeService', []);

var factory = function (app, localStorageService, $http, cart) {
	return {
		getClientToken : function(successCallback, failureCallback){
			var self = this;

			var requestUrl = "https://www.progamehub.com/mtt/Braintree/getClientToken"
			$http.get(requestUrl).then(
				function(successResponse){
					// clientId retrieved. initiate success callback
					successCallback(successResponse.data);
				},
				function(failureResponse){
					console.log(failureResponse)
				}
			);
		},
		doPayment : function(nonce, cartContainer, successCallback){
			var self = this;
			var requestUrl = "https://www.progamehub.com/mtt/Braintree/executePayment"
			app.loading=true;
			var requestParameters = {};
			requestParameters.payment_method_nonce = nonce;
			requestParameters.amount = cartContainer.totalPrice.toFixed(2);
			$http.get(requestUrl, { params : requestParameters }).then(
				function(successResponse){
					// transaction successful
					app.loading = false;
					if(successResponse.data.success == 'false'){
						failureCallback(successResponse.data)
					}else{
						successCallback(successResponse.data);
					}
				},
				function(failureResponse){
					app.loading = false;
					console.log(failureResponse)
				}
			);
		}
	};
};

braintreeService.factory('braintreeService', ['app', 'localStorageService', '$http', 'cart', factory]);
module.exports = braintreeService.name;
