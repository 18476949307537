'use strict';

var controller = function ($scope, categoryService, app, $http, $location, logger, textBundle, userSessionStore, $state, $stateParams) {
	logger('sidebar controller');
	$scope.app = app;
	$scope.state = $state;

	$scope.categoryService = categoryService;

};

module.exports = function (module) {
	var name = 'SidebarController';
	module.controller(name, controller);

	return name;
};
