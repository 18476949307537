'use strict';
var angular = require('angular');
var cartService = angular.module('cartService', []);

var factory = function (app, localStorageService, $http) {
	return {



		cartContainer : {'cartId':(Math.floor(Math.random() * (200000 - 100000 + 1)) + 100000).toString(), 'products':{}, 'cartCount':0, 'subtotal':0, 'shipping':0, 'tax':0, 'totalPrice':0, 'digital':false},
		productChunks : [],
		orderConfirmation : false,
		cartPopup : false,
		/**
		 * fetches current cart object 
		 */
		getStoredCart : function(){
			//console.log(localStorageService.get('cartContainer'));
			if(localStorageService.get('cartContainer') != null){
				this.cartContainer = localStorageService.get('cartContainer');
				this.partition(this.cartContainer['products'], 2);
			}
		},

		clearCart : function(){

			this.cartContainer = {'cartId':(Math.floor(Math.random() * (200000 - 100000 + 1)) + 100000).toString(), 'products':{}, 'cartCount':0, 'subtotal':0, 'shipping':0, 'tax':0, 'totalPrice':0, 'digital':false};
			this.productChunks = [];
			localStorageService.remove('cartContainer');
		},

		/**
		 * updates current cart object
		 */
		updateCart : function(product, quantity, productId){
			var products = this.cartContainer['products'];
			var cartCount = this.cartContainer['cartCount'];
			var subtotal = this.cartContainer['subtotal'];
			// If product already in cart, add to existing quantity
			// Otherwise, value is quantity
 			if(products[productId] != null){
				products[productId].cartQuantity = products[productId].cartQuantity + quantity;
			}
			else {
				// Add passed product data to product list
				products[productId] = product;
				products[productId].cartQuantity = quantity;
			}

			// Calculate new cart count and total price
			cartCount = cartCount + quantity;
			subtotal = subtotal + (quantity * products[productId].price);

			// If less than 0 of given product
			// Remove from cart and product list
			if(products[productId].cartQuantity <= 0){
				delete products[productId];
				// If cart is empty, reset all cart data
				if(Object.keys(products).length == 0){
					cartCount = 0;
					subtotal = 0;
				}
			}

			// set digital cart status
			this.cartContainer.digital = true;
			for(var key in products){
				products[key].digital = products[key].facets.digital
				if(products[key].facets.digital != "true"){
					this.cartContainer.digital = false;
					break;
				}
			}

			// Update local storage cart container with local version
			this.cartContainer['products'] = products;
			this.cartContainer['cartCount'] = cartCount;
			this.cartContainer['subtotal'] = subtotal;
			this.cartContainer['tax'] = subtotal * 0.05;
			this.cartContainer['shipping'] = this.cartContainer.digital ? 0 : 5;
			this.cartContainer['totalPrice'] = subtotal + this.cartContainer['tax'] + this.cartContainer['shipping'];
			localStorageService.add('cartContainer', this.cartContainer);
			this.partition(this.cartContainer['products'], 2);
		},

		partition : function(list, size){
			var listArray = Object.keys(list);
			var chunks = [];
			for (var i=0; i<listArray.length; i+=size) {
				chunks.push(listArray.slice(i, i+size));
			}
			console.log(chunks);
			this.productChunks = chunks;
		}
	};
};

cartService.factory('cart', ['app', 'localStorageService', '$http', factory]);
module.exports = cartService.name;
